import React from 'react'
import styled from 'styled-components'

interface Props {
  size: keyof typeof Comps
  text: string
  color?: string
  className?: string
}

export default function Headline({ text, size, color, className }: Props) {
  const Comp = Comps[size]

  return (
    <Comp color={color} className={className}>
      {text}
    </Comp>
  )
}

const Comps = {
  H0: styled.h1<{ color?: string }>`
    font-size: 31px;
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font-family: 'Cardo', serif;
    font-weight: bold;
  `,

  H1: styled.h1<{ color?: string }>`
    font-size: 24px;
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font-family: 'Cardo', serif;
    font-weight: bold;
    line-height: 34px;
    letter-spacing: 0;
  `,

  H2: styled.h2<{ color?: string }>`
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font: normal normal bold 24px/33px Noto Sans;
  `,

  H2Sans: styled.h2<{ color?: string }>`
    font-size: 18px;
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
  `,

  H3: styled.h3<{ color?: string }>`
    font-size: 18px;
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
    line-height: 18px;
  `,

  H4: styled.h4<{ color?: string }>`
    font-size: 14px;
    color: ${(props) => props.color || props.theme.colors.black};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
  `,

  H4Bold: styled.h4`
    font-size: 14px;
    color: ${(props) => props.color};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
  `,

  H5: styled.h5`
    font-size: 12px;
    color: ${(props) => props.color};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,

  H5Bold: styled.h5`
    font-size: 12px;
    color: ${(props) => props.color};
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
    font-weight: bold;
  `,
}

export const Headline12NotoSansBold = styled.h4<{ color: string }>`
  font-size: 12px;
  color: ${(props) => props.color};
  margin: 0;
  font-family: 'Noto Sans', sans-serif;
  font-weight: bold;
`

export const LinkText = styled.span`
  font-size: 14px;
  margin: 0;
  letter-spacing: 0;
  font: normal normal bold 14px/20px Noto Sans;
`

import React, { useState } from 'react'
import Icon from 'components/atoms/Icon'
import { Pointer } from 'components/atoms/Pointer'
import { Span } from 'components/atoms/Span'
import styled from 'styled-components'
import Button, { CloseButton } from './Button'
import { useTranslation } from 'react-i18next'

interface Props {
  value: string
  onChange(v: string): void
  onClose(): void
}

export default function SearchInput({ value, onChange, onClose }: Props) {
  const [t] = useTranslation()
  return (
    <Container>
      <Span size="14" bold>
        {t('common.search')}
      </Span>
      <input value={value} onChange={(e) => onChange(e.target.value)} autoFocus />
      <CloseButton onClick={onClose} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto minmax(0, 1fr) 30px;
  grid-gap: 10px;
  border: 1px solid #cdcdcd;
  background: white;
  border-radius: 4px;
  padding: 4px 10px;

  span {
    color: #747474;
    align-self: center;
  }
  ${Pointer} > div {
    align-items: center;
  }
  input {
    border: none;
    outline: none;
    background: transparent;
    border-left: 2px solid #74747444;
    padding: 2px 10px;
    max-width: 100%;
    line-height: 20px;
  }
`

////////////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////////////

export function SearchInputController(props: { value: string; onChange(v: string): void }) {
  const [t] = useTranslation()
  const [searchActive, setSearchActive] = useState(false)

  return searchActive || props.value?.length > 0 ? (
    <SearchInput
      value={props.value ?? ''}
      onChange={props.onChange}
      onClose={() => {
        props.onChange('')
        return setSearchActive(false)
      }}
    />
  ) : (
    <Pointer onClick={() => setSearchActive(true)}>
      <Button icon={<Icon icon="uil:search" />} appearance="flat">
        {t('common.search')}
      </Button>
    </Pointer>
  )
}

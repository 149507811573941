import { useState, useEffect, useMemo } from 'react'
import { useQueryParameter } from 'hooks/useQueryParameter'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'

const GET_MODAL_PARAM_KEY = 'modal'

const SUPERADMIN_MODALS = ['add-admin']

const useGetModalState = () => {
  const searchParams = useQueryParameter()
  const { state } = useUserContext()
  const role = state.admin?.type

  const modalName = searchParams.get(GET_MODAL_PARAM_KEY)

  const [mountedModal, setMountedModal] = useState(modalName)

  const isSuperAdminModal = modalName && SUPERADMIN_MODALS.includes(modalName)

  useEffect(() => {
    if (modalName) {
      if (isSuperAdminModal && role !== Roles.SuperAdmin) {
        return
      }
      setMountedModal(modalName)
    }

    return () => {
      setMountedModal(null)
    }
  }, [modalName, role, isSuperAdminModal])

  const isOpened = useMemo(() => Boolean(modalName), [modalName])

  return {
    mountedModal,
    isOpened,
  }
}

export { useGetModalState }

import { FC } from 'react'
import { compact, uniq, uniqBy } from 'lodash'
import { isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { UserWithFG, UsersGlobalFilter } from '../types'
import { fromNow } from 'utils/date'
import { Row } from 'react-table'
import Filter, { FilterOption } from 'components/organisms/AdvancedFilter'
import Accordion from 'components/molecules/Accordion'
import { parseInfoField } from 'utils/parseInfoField'

export const AdvancedFilters: FC<{
  filteredRows: UserWithFG[]
  globalFilter: UsersGlobalFilter
  setGlobalFilter: (filterValue: any) => void
}> = ({ filteredRows, globalFilter, setGlobalFilter }) => {
  const usersOSCount =
    filteredRows?.reduce<Record<string, number>>((acc, { id, devices }) => {
      uniqBy(devices, 'deviceos').forEach(({ deviceos }) => {
        if (deviceos) {
          acc[deviceos] = (acc[deviceos] || 0) + 1
        }
        if (deviceos?.includes('iOS')) {
          acc['iOS'] = (acc['iOS'] || 0) + 1
        }
        if (deviceos?.includes('Android')) {
          acc['Android'] = (acc['Android'] || 0) + 1
        }
        if (deviceos?.includes('iPadOS')) {
          acc['iPadOS'] = (acc['iPadOS'] || 0) + 1
        }
        if (deviceos?.includes('macOS')) {
          acc['macOS'] = (acc['macOS'] || 0) + 1
        }
        if (deviceos?.includes('Windows')) {
          acc['Windows'] = (acc['Windows'] || 0) + 1
        }
      })
      return acc
    }, {}) || {}
  const usersOS = Object.keys(usersOSCount).sort((a, b) => sortByAlphabet(a?.toString(), b?.toString()))

  const usersVersionCount =
    filteredRows?.reduce<Record<string, number>>((acc, { devices }) => {
      uniqBy(devices, 'clientversion').forEach(({ clientversion }) => {
        if (clientversion) {
          acc[clientversion] = (acc[clientversion] || 0) + 1
        }
      })
      return acc
    }, {}) || {}
  const userVersions = Object.keys(usersVersionCount).sort((a, b) => sortByAlphabet(b?.toString(), a?.toString()))

  const contactsCabinets = filteredRows
    .reduce<string[]>((acc, { roster }) => {
      if (!roster) return acc

      const { cabinet } = parseInfoField(roster.info)
      return [...acc, cabinet]
    }, [])
    .sort((a, b) => sortByAlphabet(a?.toString(), b?.toString()))
  const uniqCabinets = uniq(contactsCabinets)

  const contactsRoles = filteredRows
    .reduce<string[]>((acc, { roster }) => {
      if (!roster) return acc

      const { role } = parseInfoField(roster.info)
      return [...acc, role]
    }, [])
    .sort((a, b) => sortByAlphabet(a?.toString(), b?.toString()))
  const uniqRoles = uniq(contactsRoles)

  const userLastLogins = filteredRows
    ?.sort((a, b) => sortByDate(a.last_login!, b.last_login!))
    .reduce<(string | null)[]>((acc, { last_login }) => [...acc, fromNow(last_login)], [])

  const uniqUserLastLogins = compact(uniq(userLastLogins))

  const handleChangeAdvancedFilter = ({
    OS,
    version,
    lastLogin,
    cabinet,
    role,
  }: {
    OS?: string | null
    version?: string | null
    lastLogin?: string | null
    cabinet?: string | null
    role?: string | null
  }) => {
    setGlobalFilter({
      ...globalFilter,
      OS: OS ? (OS === globalFilter.OS ? '' : OS) : globalFilter.OS,
      version: version ? (version === globalFilter.version ? '' : version) : globalFilter.version,
      lastLogin: lastLogin ? (lastLogin === globalFilter.lastLogin ? '' : lastLogin) : globalFilter.lastLogin,
      cabinet: cabinet ? (cabinet === globalFilter.cabinet ? '' : cabinet) : globalFilter.cabinet,
      role: role ? (role === globalFilter.role ? '' : role) : globalFilter.role,
    })
  }

  return (
    <Filter>
      <Accordion
        title="OS"
        items={usersOS.map((OS) => (
          <FilterOption
            key={OS}
            filterName={OS}
            count={usersOSCount[OS]}
            isGroup={OS === 'macOS' || OS === 'Windows' || OS === 'iOS' || OS === 'Android' || OS === 'iPadOS'}
            isSelected={globalFilter.OS === OS}
            onClick={() => handleChangeAdvancedFilter({ OS })}
          />
        ))}
      />
      <Accordion
        title="Version"
        items={userVersions.map((version) => (
          <FilterOption
            key={version}
            filterName={version}
            count={usersVersionCount[version]}
            isSelected={globalFilter.version === version}
            onClick={() => handleChangeAdvancedFilter({ version })}
          />
        ))}
      />
      <Accordion
        title="Cabinet"
        items={uniqCabinets.map((cabinet) => (
          <FilterOption
            key={cabinet}
            filterName={cabinet}
            count={contactsCabinets.filter((c) => c === cabinet).length}
            isSelected={globalFilter.cabinet === cabinet}
            onClick={() => handleChangeAdvancedFilter({ cabinet })}
          />
        ))}
      />
      <Accordion
        title="Role"
        items={uniqRoles.map((role) => (
          <FilterOption
            key={role}
            filterName={role}
            count={contactsRoles.filter((r) => r === role).length}
            isSelected={globalFilter.role === role}
            onClick={() => handleChangeAdvancedFilter({ role })}
          />
        ))}
      />
      <Accordion
        title="Last login"
        items={uniqUserLastLogins.map((lastLogin) => (
          <FilterOption
            key={lastLogin}
            filterName={lastLogin}
            count={userLastLogins?.filter((userLastLogin) => userLastLogin === lastLogin).length}
            isSelected={globalFilter.lastLogin === lastLogin}
            onClick={() => handleChangeAdvancedFilter({ lastLogin })}
          />
        ))}
      />
    </Filter>
  )
}

export const usersGlobalFilter = (
  rows: Row<UserWithFG>[],
  _columnIds: string[],
  filterValue: UsersGlobalFilter
): Row<UserWithFG>[] => {
  let remainingRows = [...rows]

  remainingRows = remainingRows.filter((r) => {
    const { name, last_login } = r.original
    const isActive = Boolean(last_login)
    const isTestUser = name.toLocaleLowerCase().includes('test')

    if (filterValue.status === 'active') {
      return isActive && !isTestUser
    }
    if (filterValue.status === 'pending') {
      return !isActive && !isTestUser
    }
    if (filterValue.status === 'test') {
      return isTestUser
    }

    return !isTestUser
  })

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { name } = r.original

      if (isIncludeFields([name], regExp)) {
        return true
      }
      return false
    })
  }

  if (filterValue.OS) {
    remainingRows = remainingRows.filter((r) => {
      const { devices } = r.original

      if (devices.some(({ deviceos }) => deviceos?.includes(filterValue.OS))) {
        return true
      }
      return false
    })
  }

  if (filterValue.version) {
    remainingRows = remainingRows.filter((r) => {
      const { devices } = r.original

      if (devices.some(({ clientversion }) => clientversion === filterValue.version)) {
        return true
      }
      return false
    })
  }

  if (filterValue.cabinet) {
    remainingRows = remainingRows.filter((r) => {
      const { roster } = r.original
      if (!roster) return false

      const { cabinet } = parseInfoField(roster.info)
      return cabinet === filterValue.cabinet
    })
  }

  if (filterValue.role) {
    remainingRows = remainingRows.filter((r) => {
      const { roster } = r.original
      if (!roster) return false

      const { role } = parseInfoField(roster.info)
      return role === filterValue.role
    })
  }

  if (filterValue.lastLogin) {
    remainingRows = remainingRows.filter((r) => {
      const { last_login } = r.original

      if (fromNow(last_login) === filterValue.lastLogin) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Pointer = styled.div`
  display: contents;
  cursor: pointer;
`

export const PointerGrid = styled.div<{ onClick?: any }>`
  display: grid;
  opacity: ${(p) => (p.onClick ? 1 : 0.5)};
  align-self: stretch;
  cursor: pointer;
`

export const PointerGridCenter = styled(PointerGrid)`
  align-items: center;
`

export const LinkTo = styled(Link)`
  display: contents;
  color: inherit;
  cursor: pointer;
`

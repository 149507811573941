import React, { FC, ReactNode } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const StyledPopup = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 120px;
  max-height: 200px;
  padding: 8px 0;
  border-radius: 10px;
  background-color: #fbfbfb;
  box-shadow: 0 5px 15px rgba(167, 169, 189, 0.4);
  border: 1px solid #cccccc70;
  z-index: 99;

  .scroll-wrapper {
    overflow: auto;

    &::-webkit-scrollbar {
      appearance: none;

      &:vertical {
        width: 6px;
      }

      &-thumb {
        background-color: #fff;
        border-radius: 3px;
      }
    }
  }
`

const StyledMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  min-height: 32px;
  padding: 6px 16px;
  background-color: #fbfbfb;
  color: #4f4f4f;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;

  .menu-item__image {
    width: 20px;
  }

  &.menu-item--disabled {
    cursor: not-allowed;
    opacity: 0.48;
  }

  &:hover {
    background-color: #62b0db1a;
    color: #181818;
    transition: 0.1s;

    svg {
      fill: #181818;
      transition: 0.1s;
    }
  }

  svg {
    fill: #4f4f4f;
  }

  &.menu-item--color--red {
    color: #d06d6e;

    svg {
      fill: #d06d6e;
    }
  }
`

interface PopupProps {
  children: ReactNode
  className?: string
}

interface MenuItemProps {
  children: ReactNode
  value: string
  icon?: ReactNode
  red?: boolean
  className?: string
  disabled?: boolean
  onClick: (value: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const MenuItem: FC<MenuItemProps> = ({ children, icon, className, red, value, disabled, onClick }) => (
  <StyledMenuItem
    className={cn(className, { 'menu-item--color--red': red, 'menu-item--disabled': disabled })}
    onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => onClick(value, event)}
  >
    {children}
    {icon || null}
  </StyledMenuItem>
)

const Popup: FC<PopupProps> = ({ children, className }) => (
  <StyledPopup className={className}>
    <div className="scroll-wrapper">{children}</div>
  </StyledPopup>
)

export { Popup, MenuItem }

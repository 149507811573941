import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import cn from 'classnames'

const StyledCheckbox = styled.div`
  width: fit-content;
  &.controlled {
    cursor: pointer;
  }
`

interface CheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
  checked?: boolean
  onToggle?: (checked?: boolean) => void
}

const Checkbox: FC<CheckboxProps> = ({ checked = false, onToggle, ...rest }: CheckboxProps) => (
  <StyledCheckbox
    onClick={() => onToggle && onToggle(!Boolean(checked))}
    className={cn({ controlled: Boolean(onToggle) })}
    {...rest}
  >
    {checked ? (
      <Icon icon="uil:check-square" width={30} color="#499976" />
    ) : (
      <Icon icon="uil:square" width={30} color="#8d8d8d" />
    )}
  </StyledCheckbox>
)

export { Checkbox }

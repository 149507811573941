import { FC, ReactNode } from 'react'
import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next, I18nextProvider } from 'react-i18next'
import enLocales from './en.json'
import ptLocales from './pt.json'

const bundledResources = {
  en: { translations: enLocales },
  pt: { translations: ptLocales },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: bundledResources,
    lng: localStorage.getItem('i18nextLng') || 'en',
    supportedLngs: ['en', 'pt'],
    fallbackLng: 'en',
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
    saveMissing: true,
    saveMissingTo: 'all',
  })

export const LocalizationProvider: FC<{ children: ReactNode }> = ({ children }) => {
  return <I18nextProvider i18n={i18n}>{children}</I18nextProvider>
}

import Icon from 'components/atoms/Icon'
import Input, { TextArea } from 'components/atoms/Input'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import Tooltip from 'components/atoms/Tooltip'
import React from 'react'
import styled from 'styled-components'

type InputType = Parameters<typeof Input>[0]
interface Props extends InputType {
  caption: string
  tooltip?: string | null
}

export default function InputWithCaptions({ tooltip, ...props }: Props) {
  return (
    <Container>
      <Title>
        <GreyParagraph2 size="11" text={props.caption} />
        {tooltip && (
          <Tooltip message={tooltip} width={260}>
            <Icon icon="uil:question-circle" style={{ marginLeft: '4px' }} width="12px" />
          </Tooltip>
        )}
      </Title>
      <Input {...props} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;

  & > input {
    min-height: 48px;

    ::placeholder {
      font-size: 12px;
    }
  }
`
const Title = styled.div`
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-bottom: 5px;
`

interface TextAreaProps {
  caption: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
export function TextAreaWithCaptions(p: TextAreaProps) {
  return (
    <Container>
      <Title>
        <GreyParagraph2 size="11" text={p.caption} />
      </Title>
      <TextArea {...p} />
    </Container>
  )
}

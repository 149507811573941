import React from 'react'
import styled from 'styled-components'

interface Props {
  placeholder: string
  type: 'text' | 'email' | 'password'
  value: string
  disabled?: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export default function InputField({ placeholder, value, type, disabled, onChange }: Props) {
  return (
    <Container>
      <input placeholder={placeholder} value={value} onChange={onChange} disabled={disabled} type={type} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid: auto / 1fr 30px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: white;
  border: 1px solid #74747488;
  border-radius: 4px;
  padding: 5px 5px 5px 10px;

  input {
    border: none;
    outline: none;
  }
`

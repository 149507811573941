import React, { useContext } from 'react'
import { ConfirmContext, HIDE_CONFIRM, SHOW_CONFIRM } from './ConfirmContext'

let resolveCallback: (r: boolean) => void

export const useConfirm = () => {
  const [confirmState, dispatch] = useContext<any>(ConfirmContext)

  const onConfirm = () => {
    closeConfirm()
    resolveCallback(true)
  }

  const onCancel = () => {
    closeConfirm()
    resolveCallback(false)
  }
  const confirm = (text: string | React.ReactElement, customAction?: React.ReactNode) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        text,
        customAction,
      },
    })
    return new Promise((res) => {
      resolveCallback = res
    })
  }

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    })
  }

  return { confirm, onConfirm, onCancel, confirmState }
}

import React from 'react'
import Headline from 'components/atoms/Headline'
import Icon from 'components/atoms/Icon'
import { Span } from 'components/atoms/Span'
import styled from 'styled-components'
import NoResultImage from 'components/assets/no_result.png'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'

export default function NoResultBox({
  clearFilter,
  clearSearch,
}: {
  clearFilter?: () => void
  clearSearch?: () => void
}) {
  const [t] = useTranslation()
  return (
    <Container>
      <img src={NoResultImage} alt="no result" />
      <Headline size="H3" text={t('search.noResult.title')} />
      <Span size="12">{t('search.noResult.text')}</Span>
      <hr />
      <div>
        {clearSearch ? (
          <Button icon={<Icon icon="uil:search" />} appearance="flat" onClick={clearSearch}>
            {t('search.noResult.clear')}
          </Button>
        ) : null}
        {clearFilter ? (
          <Button icon={<Icon icon="uil:filter" />} appearance="flat" onClick={clearFilter}>
            {t('search.noResult.clearFilters')}
          </Button>
        ) : null}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid: 170px auto auto auto auto/ auto;
  margin: 100px 0;
  gap: 15px;
  justify-content: center;
  text-align: center;
  img {
    height: 100%;
    justify-self: center;
  }
  hr {
    width: 100%;
    height: 1px;
    border: 0;
    background: #979797;
    margin: 15px 0;
  }

  > div:last-child {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`

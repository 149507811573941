import { ReactNode } from 'react'
import styled from 'styled-components'

export const FormField = ({ children, error }: { children: ReactNode; error?: string }) => (
  <div>
    {children}
    <ErrorMessage>{error}</ErrorMessage>
  </div>
)

const ErrorMessage = styled.span`
  font-size: 12px;
  color: #d06d6e;
  text-overflow: ellipsis;
  overflow: hidden;
`

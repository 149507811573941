import React, { FC } from 'react'
import styled from 'styled-components'

export default function TaglineLoader({ height }: { height?: string }) {
  return (
    <Container height={height}>
      <Tag />
      <Line />
    </Container>
  )
}

const Container = styled.div<{ height?: string }>`
  display: flex;
  gap: 12px;
  height: ${(props) => (props.height ? props.height : '70vh')};
  align-items: center;
  justify-content: center;

  & > * {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    mix-blend-mode: multiply;
  }

  @keyframes move-right {
    0%,
    10% {
      transform: scale(1) translateX(0);
    }
    30% {
      transform: scale(1.2) translateX(25px);
    }
    50%,
    60% {
      transform: scale(1) translateX(50px);
    }
    80% {
      transform: scale(0.8) translateX(25px);
    }
  }

  @keyframes move-left {
    0%,
    10% {
      transform: scale(1) translateX(0);
    }
    30% {
      transform: scale(0.8) translateX(-25px);
    }
    50%,
    60% {
      transform: scale(1) translateX(-50px);
    }
    80% {
      transform: scale(1.2) translateX(-25px);
    }
  }
`

const Tag = styled.div`
  background-color: #57c871;
  animation: move-right 1.2s linear infinite;
`

const Line = styled.div`
  background-color: #4b61da;
  animation: move-left 1.2s linear infinite;
`

export const LoadingPage: FC = () => (
  <Page>
    <TaglineLoader />
  </Page>
)

const Page = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`

import React, { FC } from 'react'
import Headline from 'components/atoms/Headline'
import styled from 'styled-components'
import { Pointer } from 'components/atoms/Pointer'
import cn from 'classnames'

interface TabProps {
  label: string
  value: string
  hidden?: boolean
}

export const Tab: FC<TabProps> = () => {
  return <div />
}

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactElement<TabProps>[]
  activeTab: string
  onSwitch: (tab: any) => void
}

const Tabs: FC<Props> = ({ children, activeTab, onSwitch, ...rest }) => {
  return (
    <Container {...rest}>
      {children.map(
        ({ props: { label, value, hidden } }) =>
          !hidden && (
            <Pointer className={cn({ isActive: activeTab === value })} onClick={() => onSwitch(value)} key={value}>
              <Headline size="H4" text={label} />
            </Pointer>
          )
      )}
    </Container>
  )
}

const Container = styled.div`
  background: white;
  width: fit-content;
  border: 3px solid white;
  border-radius: 40px;
  display: grid;
  grid-template-columns: repeat(5, auto);
  overflow: auto;
  height: 40px;
  z-index: 1;

  div {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    min-width: 48px;
    h4 {
      color: #b9b9b9;
    }
    &.isActive {
      background: #b9b9b933;
      h4 {
        color: black;
      }
    }
  }
`
export default Tabs

import styled from "styled-components";

export const Gap5 = styled.div`
  height: 5px;
`;

export const Gap10 = styled.div`
  height: 10px;
`;
export const Gap20 = styled.div`
  height: 20px;
`;
export const Gap30 = styled.div`
  height: 30px;
`;
export const Gap40 = styled.div`
  height: 40px;
`;
export const Gap60 = styled.div`
  height: 60px;
`;

export const GapW10 = styled.span`
  display: inline-block;
  width: 10px;
`;

export const GapW20 = styled.span`
  display: inline-block;
  width: 20px;
`;

export const GapW25 = styled.span`
  display: inline-block;
  width: 25px;
`;

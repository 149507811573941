import { useCallback } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

export function useCloseModal() {
  const { pathname } = useLocation()
  const navigate = useNavigate()

  return useCallback(() => {
    navigate(pathname)
  }, [navigate, pathname])
}

import { Icon } from '@iconify/react'
import React, { useState, ReactNode } from 'react'
import styled from 'styled-components'

interface AccordionProps {
  title: string
  items: ReactNode[]
}

const defaultLength = 8

const Accordion: React.FC<AccordionProps> = ({ title, items }) => {
  const [isOpen, setIsOpen] = useState(false)

  const isEmpty = items.length === 0
  const isEnabled = items.length > defaultLength

  const visibleItems = isOpen ? items : items.slice(0, defaultLength)

  const toggleAccordion = () => {
    isEnabled && setIsOpen(!isOpen)
  }

  if (isEmpty) return null

  return (
    <AccordionContainer>
      <AccordionHeader isEnabled={isEnabled} onClick={toggleAccordion}>
        <AccordionLabel>{title}</AccordionLabel>
        {isEnabled && <Icon color="#616161" fontSize={22} icon={isOpen ? 'uil:angle-up' : 'uil:angle-down'} />}
      </AccordionHeader>
      <AccordionContent isOpen={isOpen}>
        {visibleItems.map((item, index) => (
          <div key={index}>{item}</div>
        ))}
      </AccordionContent>
      {isEnabled && (
        <Toggle onClick={toggleAccordion}>
          {isOpen ? 'Less' : 'More'}
          <Icon
            color="#616161"
            fontSize={14}
            style={{ marginLeft: 4 }}
            icon={isOpen ? 'uil:angle-up' : 'uil:angle-down'}
          />
        </Toggle>
      )}
    </AccordionContainer>
  )
}

const AccordionContainer = styled.div`
  padding: 10px 16px;
  border-bottom: 1px solid #ccc;
`

const AccordionHeader = styled.div<{ isEnabled: boolean }>`
  cursor: ${(props) => (props.isEnabled ? 'pointer' : 'auto')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 22px;
  margin-bottom: 16px;
`

const AccordionLabel = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
`

const AccordionContent = styled.div<{ isOpen: boolean }>`
  overflow: hidden;
  transition: max-height 0.2s ease-out, padding 0.2s ease-out;
  padding: ${(props) => (props.isOpen ? '4px 0' : '0')};
`

const Toggle = styled.div`
  color: #616161;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  margin-top: 4px;
  cursor: pointer;

  &:hover {
    filter: brightness(1.11);
  }
`

export default Accordion

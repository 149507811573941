import React, { FC, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { AdminUserCredentials, Realm } from '@types'
import { toast } from 'react-toastify'
import { editAdminFn, getAdminsFn } from 'api'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { useUserContext } from 'contexts/UserContext'
import { FormField } from 'components/molecules/Form'
import { adminTypesOptions } from '..'

const schema = z.object({
  username: z.string().min(1, { message: 'Required' }),
  type: z.string().startsWith('ADMINISTRATOR', { message: 'Choose admin type' }),
  realms: z.object({ value: z.number(), label: z.string() }).array(),
})

const EditAdmin: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const queryClient = useQueryClient()
  const { state, isSuperAdmin } = useUserContext()
  const { i_realm } = state?.realm
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    reset,
  } = useForm<AdminUserCredentials & { newPassword: string }>({ resolver: zodResolver(schema) })
  const realms = queryClient.getQueryData('realms') as Realm[]

  const { data: admin } = useQuery(['admins', i_realm], getAdminsFn, {
    select: (data) => data.filter(({ id }) => +params.id! === id)?.[0],
  })

  const realmsOptions = useMemo(
    () => (realms || []).map((realm) => ({ value: realm.i_realm, label: realm.name })),
    [realms]
  )

  const { mutate: editAdmin } = useMutation((credentials: any) => editAdminFn({ ...credentials, id: +params.id! }), {
    onSuccess: ({ username, type }) => {
      queryClient.invalidateQueries('admins')
      toast.success(t('modal.editAdmin.editSuccess', { type, username }))
      onClose()
    },
  })

  const onSubmit = (credentials: AdminUserCredentials) => {
    editAdmin({
      username: credentials.username,
      type: credentials.type,
      realms: credentials.realms.map(({ value }) => value),
    })
  }

  useEffect(() => {
    if (admin) {
      const realmsIds = realmsOptions.filter(({ value }) =>
        admin.admin_realms.map(({ i_realm }) => i_realm).includes(value)
      )

      reset({
        username: admin.username,
        type: admin.type,
        realms: realmsIds,
      })
    }
  }, [admin, realmsOptions, reset])

  if (!isSuperAdmin) return null

  return (
    <ModalView title={t('modal.editAdmin.title')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="username"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions type="text" caption={t('common.username')} {...props} />
                </FormField>
              )}
            />
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.type')} />
              <Controller
                name="type"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <FormField error={errors[name]?.message}>
                      <ReactSelect
                        value={adminTypesOptions.filter((g) => g.value === value)}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        options={adminTypesOptions}
                        placeholder={t('modal.addAdmin.choose')}
                        {...props}
                      />
                    </FormField>
                  )
                }}
              />
            </div>
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.realms')} />
              <Controller
                name="realms"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <FormField error={errors[name]?.message}>
                      <ReactSelect
                        placeholder={t('placeholder.chooseRealm')}
                        value={value}
                        onChange={onChange}
                        options={realmsOptions}
                        isMulti
                      />
                    </FormField>
                  )
                }}
              />
            </div>
          </Field>

          <Button style={{ alignSelf: 'flex-end' }} appearance="blue" type="submit" disabled={!isDirty}>
            {t('modal.editAdmin.edit')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    flex: 1;
  }
`

export default EditAdmin

import React, { FC } from 'react'
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

interface Props {
  title: string
  children: React.ReactNode
  filter?: React.ReactNode
}

const Page: FC<Props> = ({ title, children, filter }) => {
  const [t] = useTranslation()
  return (
    <Container>
      <Helmet>
        <title>
          {title} | {t('common.adminPanel')}
        </title>
      </Helmet>
      <ContentWrapper>
        <ChildWrapper>{children}</ChildWrapper>
        {filter && <FilterWrapper>{filter}</FilterWrapper>}
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 200px);
  padding: 20px;
  background-color: #f5f5f5;

  @media screen and (max-width: 856px) {
    min-width: calc(100% - 56px);
  }
`

const ContentWrapper = styled.div`
  display: flex;
`
const ChildWrapper = styled.div`
  width: 100%;
  margin-right: 16px;
`

const FilterWrapper = styled.div`
  margin: -20px -20px 0 0;
  border-left: 1px solid #ccc;
  min-height: 100vh;
`

export { Page }

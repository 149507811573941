import React, { ButtonHTMLAttributes, cloneElement, FC, isValidElement, ReactElement, ReactNode, memo } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  padding: 10px 12px;
  border: none;
  border-radius: 12px;
  outline: none;
  cursor: pointer;
  height: 40px;
  transition: all 0.2s;
  min-width: 88px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    color: #000;

    filter: contrast(1.11);

    .button__icon {
      fill: #000;
    }
  }

  .button__text {
    font-weight: 600;
    font-size: 14px;
  }

  .button__icon {
    margin-right: 4px;
    fill: #252525;
  }

  &.with-shadow {
    &:hover {
      box-shadow: 0 3px 7px rgb(167 169 189 / 20%);
    }
  }

  &.button--appearance--default {
    background-color: #fff;
  }

  &.button--appearance--flat {
    background-color: inherit;
  }

  &.button--appearance--outline {
    background-color: inherit;
    border: 1px solid #5a91b0;
    color: #5a91b0;
  }

  &.button--appearance--blue {
    background-color: #5a91b0;
    color: #fff;

    .button__icon {
      fill: #fff;
    }
  }

  &.button--appearance--grey {
    background-color: #ccc;
    color: #fff;

    .button__icon {
      fill: #fff;
    }
  }

  &.button--appearance--red {
    background-color: #fff0;
    border: 1px solid #d06d6e;
    color: #d06d6e;

    .button__icon {
      fill: #fff;
    }
  }
`

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  appearance?: 'default' | 'flat' | 'blue' | 'grey' | 'red' | 'outline'
  icon?: ReactNode
  disabled?: boolean
}

const Button: FC<ButtonProps> = ({ children, icon, appearance = 'default', className, ...rest }) => {
  const buttonIcon = icon && isValidElement(icon) && cloneElement(icon as ReactElement, { className: 'button__icon' })

  return (
    <StyledButton
      className={cn(className, 'text-main-reg', `button--appearance--${appearance}`, {
        'with-shadow': appearance !== 'flat',
      })}
      {...rest}
    >
      {buttonIcon}
      <span className="button__text">{children}</span>
    </StyledButton>
  )
}

export default memo(Button)

import { getContactsFn, updateContactsFn } from 'api'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

export function useContacts(i_realm: number) {
  return useQuery(['contacts', i_realm], getContactsFn, {
    select: (data) =>
      data.filter(
        ({ firstname, lastname, identity }) =>
          ![firstname, lastname, identity].some((val) => val?.toLowerCase().includes('test'))
      ),
  })
}

export function useUpdateContact() {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  return useMutation(updateContactsFn, {
    onError: (e) => {
      toast.error('something went wrong, please try again...')
    },
    onSuccess: (a, b) => {
      queryClient.invalidateQueries(['contacts'])

      navigate(-1)
      toast.success('Contact was successfully edited!')
    },
  })
}

////////////////////////////////////////////////////////////////////

import dayjs from 'dayjs'

////////////////////////////////////////////////////////////////////
const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export function getDateValueMessages(date: string) {
  const djsDate = dayjs(date, DATE_FORMAT)
  const today = dayjs()

  if (djsDate.isAfter(dayjs().subtract(1, 'week'))) {
    if (djsDate.isSame(today, 'day')) {
      return 'Today'
    }

    if (djsDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday'
    }

    // @ts-ignore
    return days[djsDate.weekday()]
  }

  return date
}
////////////////////////////////////////////////////////////////////

export function getDateValueNotifications(date: string) {
  const djsDate = dayjs(date)
  const today = dayjs()

  if (djsDate.isAfter(dayjs().subtract(1, 'week'))) {
    if (djsDate.isSame(today, 'm')) {
      return 'Just now'
    }

    if (djsDate.isSame(today, 'day')) {
      return djsDate.format('HH:mm')
    }

    if (djsDate.isSame(today.clone().subtract(1, 'day'), 'day')) {
      return 'Yesterday'
    }

    // @ts-ignore
    return days[djsDate.weekday()]
  }

  return dayjs().format(DATE_FORMAT)
}
////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = 'DD MMM YYYY - HH:mm'

////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////

export function fromNow(inputDate: string | Date | null): string {
  const now = dayjs()
  const date = dayjs(inputDate)

  const isToday = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isSame(now, 'day')
  }

  const isYesterday = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isSame(now.subtract(1, 'day'), 'day')
  }

  const isThisWeek = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isSame(now, 'week')
  }

  const isThisMonth = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isSame(now, 'month')
  }

  const isLast3Months = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isAfter(now.subtract(3, 'month')) && date.isBefore(now)
  }
  const isLast6Months = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isAfter(now.subtract(6, 'month')) && date.isBefore(now)
  }

  const isLastYear = (date: dayjs.Dayjs, now: dayjs.Dayjs): boolean => {
    return date.isSame(now.subtract(1, 'year'), 'year')
  }

  const checks = [
    { check: isToday, message: 'Today' },
    { check: isYesterday, message: 'Yesterday' },
    { check: isThisWeek, message: 'This week' },
    { check: isThisMonth, message: 'This month' },
    { check: isLast3Months, message: 'Last 3 months' },
    { check: isLast6Months, message: 'Last 6 months' },
    { check: isLastYear, message: 'Last year' },
  ]

  for (const { check, message } of checks) {
    if (check(date, now)) {
      return message
    }
  }

  const seconds = Math.floor(now.diff(date, 'second'))
  const intervals: { [key: string]: number } = {
    year: 31536000,
    month: 2592000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  }

  for (const interval in intervals) {
    const count = Math.floor(seconds / intervals[interval])
    if (count > 0) {
      return count === 1 ? `1 ${interval} ago` : `${count} ${interval}s ago`
    }
  }

  return 'Not active'
}

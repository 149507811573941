import React from 'react'
import styled from 'styled-components'
import { BlackParagraph } from './Paragraph'

interface Props {
  text: string
  color: string
}

export default function DotAndText({ text, color }: Props) {
  return (
    <Container>
      <Dot color={color} />
      <BlackParagraph size="12" text={text} />
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 10px;
  align-items: center;
`

const Dot = styled.span<{ color?: string }>`
  position: relative;
  top: 1px;
  width: 7px;
  height: 7px;
  border-radius: 7px;
  background-color: ${(p) => p.color || p.theme.colors.tropicalGreen};
`

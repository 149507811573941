import React, { FC } from 'react'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { getChangelogFn, getVersionFn } from 'api'

const Changelog: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const { data: app } = useQuery(['version'], getVersionFn)
  const { data: changelog } = useQuery(['changelog'], getChangelogFn)

  return (
    <ModalView title={`${t('common.adminPanel')} - ${app?.version}`} style={{ minWidth: '600px' }} onClose={onClose}>
      {changelog?.html && <div dangerouslySetInnerHTML={{ __html: changelog.html }} />}
    </ModalView>
  )
}

export default Changelog

import React from 'react'
import Icon from 'components/atoms/Icon'

interface Props {
  canSort: boolean
  isSorted: boolean
  isSortedDesc?: boolean
}

export default function SortChevron({ canSort, isSorted, isSortedDesc }: Props) {
  if (!canSort) {
    return null
  }

  if (!isSorted) return <Icon icon="uil:angle-down" color="#ccc" />

  return isSortedDesc ? <Icon icon="uil:angle-down" /> : <Icon icon="uil:angle-up" />
}

import React, { FC, createContext, useReducer, Dispatch } from 'react'

type ContextType = [{ show: boolean; text?: string | React.ReactElement }, Dispatch<any>] | undefined

export const SHOW_CONFIRM = 'SHOW_CONFIRM'
export const HIDE_CONFIRM = 'HIDE_CONFIRM'

const initialState = {
  show: false,
}

const reducer = (
  state = initialState,
  action: { type: string; payload: { text?: string | React.ReactElement; customAction?: React.ReactNode } }
) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
        customAction: action.payload.customAction,
      }
    case HIDE_CONFIRM:
      return initialState
    default:
      return initialState
  }
}

export const ConfirmContext = createContext<ContextType>(undefined)

const ConfirmDialogProvider: FC<{ children: React.ReactNode }> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return <ConfirmContext.Provider value={[state, dispatch]} {...props} />
}

export { ConfirmDialogProvider }

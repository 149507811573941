import React, { FC, useState, useRef, ReactNode, cloneElement, ReactElement, useCallback } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

import { useOnClickOutside } from 'hooks/useOnClickOutside'

const StyledButton = styled.button`
  padding: 6px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  border-radius: 8px;
  outline: none;

  &:hover {
    cursor: pointer;
  }

  &.button--appearance--default {
    background: #82aceb
    box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.24);
  }

  &.button--appearance--flat {
    background-color: #d1d1d1 .button__icon {
      margin-left: 4px;
      fill: #383838;
    }

    .button__title {
      color: #383838;
    }
  }

  .button__title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: #fff
  }

  .button__icon {
    transform: rotate(180deg);
    fill: #fff
  }

  .button__icon-container {
    display: flex;
    padding-left: 4px;

    .isOpen {
      transform: none;
    }
  }
`

interface DropdownButtonProps {
  appearance: 'default' | 'flat'
  isMenuOpen: boolean
  title?: string
  className?: string
  onClick: () => void
}

const DropdownButton: FC<DropdownButtonProps> = ({ title, isMenuOpen, className, onClick, appearance }) => (
  <StyledButton onClick={onClick} type="button" className={cn(className, `button--appearance--${appearance}`)}>
    <span className="button__title">{title}</span>
    <div className="button__icon-container">
      <div className={cn('button__icon', { isOpen: isMenuOpen })} />
    </div>
  </StyledButton>
)

const StyledDropdown = styled.div`
  display: flex;
  width: fit-content;
  position: relative;

  .dropdown__button {
    &:hover {
      cursor: pointer;
    }
  }

  .dropdown__menu {
    display: flex;
    position: absolute;
    width: max-content;
  }
  .position__right {
    right: 32px;
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      margin-top: -15px;
      right: -10px;
      border: solid 5px transparent;
      border-left-color: #fff;
      z-index: 1;
    }
  }
  .position__top {
    bottom: 32px;
  }
`

interface DropdownProps {
  appearance?: 'default' | 'flat'
  position?: 'top' | 'right'
  children: ReactNode
  title?: string
  button?: ReactElement
  autoClose?: boolean
  className?: string
}

const Dropdown: FC<DropdownProps> = ({
  children,
  button,
  title,
  className,
  autoClose = true,
  appearance = 'default',
  position = 'right',
}) => {
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false)
  const ref = useRef(null)

  const handleStopPropagation = useCallback(
    (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation(),
    []
  )

  const handleCloseMenu = () => {
    setMenuOpen(false)
  }
  const handleToggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  useOnClickOutside(ref, handleCloseMenu)

  const dropdownButton =
    button &&
    cloneElement(button, {
      className: 'dropdown__button',
      onClick: handleToggleMenu,
    })

  return (
    <StyledDropdown ref={ref} className={className || ''} onClick={handleStopPropagation}>
      {dropdownButton || (
        <DropdownButton isMenuOpen={isMenuOpen} title={title} onClick={handleToggleMenu} appearance={appearance} />
      )}
      {isMenuOpen && (
        <div
          className={cn('dropdown__menu', `position__${position}`)}
          onClick={autoClose ? handleCloseMenu : undefined}
        >
          {children}
        </div>
      )}
    </StyledDropdown>
  )
}

export { Dropdown }

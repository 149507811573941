export const emptyArray = []

export function notEmpty<TValue>(value: TValue | null | undefined): value is TValue {
  return value !== null && value !== undefined
}

export function onlyUnique(value: any, index: number, self: Array<any>) {
  return self.indexOf(value) === index
}

export const isIncludeFields = (fields: (string | null | undefined)[], regExp: RegExp) =>
  fields.some((field) => field && regExp.test(field))

export const sortByAlphabet = (a?: string, b?: string) =>
  !a
    ? 1
    : !b
    ? -1
    : a.trim().toLowerCase() < b.trim().toLowerCase()
    ? -1
    : a.trim().toLowerCase() > b.trim().toLowerCase()
    ? 1
    : 0

export const sortByDate = (a: string, b: string) => new Date(b).valueOf() - new Date(a).valueOf()

export const sortByNumber = (a: number, b: number) => a - b

export function arrayToCSV(data: { [key: string]: string | number | null }[]): string {
  if (data.length === 0) return ''

  const headers = Object.keys(data[0])
  const csvRows: string[] = []

  // Add the header row
  csvRows.push(headers.join(','))

  // Add the data rows
  data.forEach((row) => {
    const values = headers.map((header) => {
      const value = row[header]
      if (value === null) {
        return ''
      } else if (typeof value === 'string') {
        // Escape double quotes in the value
        return `"${value.replace(/"/g, '""')}"`
      } else {
        return value?.toString() || ''
      }
    })
    csvRows.push(values.join(','))
  })

  return csvRows.join('\n')
}

import React, { CSSProperties } from 'react'
import styled from 'styled-components'

interface Props {
  text: string | number | React.ReactNode
  size?: keyof typeof Comps
  className?: string
  style?: CSSProperties
}

export default function Paragraph({ text, size = '12', className, style }: Props) {
  const Comp = Comps[size]

  return (
    <Comp className={className} style={style}>
      {text}
    </Comp>
  )
}

const Comps = {
  '9': styled.p`
    font-size: 9px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
  '10': styled.p`
    font-size: 10px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
  '11': styled.p`
    font-size: 11px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
  '12': styled.p`
    font-size: 12px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
  '13': styled.p`
    font-size: 13px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
  '14': styled.p`
    font-size: 14px;
    margin: 0;
    font-family: 'Noto Sans', sans-serif;
  `,
}

export const BlackParagraph = styled(Paragraph)`
  color: ${(p) => p.theme.colors.black};
`

export const RedParagraph = styled(Paragraph)`
  color: ${(p) => p.theme.colors.camineRed};
`

export const BoldParagraph = styled(Paragraph)`
  font-weight: bold;
`

export const GreyParagraph = styled(Paragraph)`
  color: #b9b9b9;
`

export const GreyParagraph2 = styled(Paragraph)`
  color: ${(p) => p.theme.colors.coolGrey2};
`

export const GreyBoldParagraph = styled(GreyParagraph)`
  font-weight: bold;
`

export const GreyBoldParagraph2 = styled(GreyParagraph2)`
  font-weight: bold;
`

export const BlackBoldParagraph = styled(BlackParagraph)`
  font-weight: bold;
`

export const LetterSpacingParagraph = styled(Paragraph)`
  letter-spacing: 1.3px;
`

export const LetterSpacingParagraphBold = styled(Paragraph)`
  letter-spacing: 1.3px;
  font-weight: bold;
`

export const GreyLetterSpacingParagraphBold = styled(GreyBoldParagraph)`
  letter-spacing: 1.3px;
`

export const WhiteLetterSpacingParagraphBold = styled(BlackBoldParagraph)`
  letter-spacing: 1.3px;
  color: white;
`

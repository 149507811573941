import React from 'react'

export function highlight(text: string, highlightText?: string) {
  if (!highlightText) {
    return text
  }
  // Split on highlight term and include term into parts, ignore case
  const escapedHighlight = highlightText.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  const parts = text?.split(new RegExp(`(${escapedHighlight})`, 'gi')).filter((p) => !!p)

  return (
    <span>
      {parts?.map((part, i) => (
        <span key={i} style={part?.toLowerCase() === highlightText?.toLowerCase() ? { background: '#fff83090' } : {}}>
          {part}
        </span>
      ))}
    </span>
  )
}

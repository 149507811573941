import { Navigate, Outlet } from 'react-router-dom'
import { useUserContext } from 'contexts/UserContext'
import { AdminType } from '@types'

const RoleGuard = ({ allowedRoles }: { allowedRoles: AdminType[] }) => {
  const userContext = useUserContext()

  const { admin } = userContext.state

  return admin && allowedRoles.includes(admin.type) ? <Outlet /> : <Navigate to="/" replace />
}

export { RoleGuard }

import { useUserContext } from 'contexts/UserContext'
import { ReactNode } from 'react'

import { Navigate } from 'react-router-dom'

type AuthGuardProps = {
  children: ReactNode
  isAllowed?: (auth: { isLoggedIn: boolean }) => boolean
}

export function AuthGuard({ children, isAllowed }: AuthGuardProps) {
  const { token } = useUserContext()

  if (!token) {
    return <Navigate to="/login" />
  }

  if (isAllowed && token) {
    return <Navigate to="/" />
  }

  return <>{children}</>
}

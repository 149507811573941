import React, { FC } from 'react'
import { logoutUserFn } from 'api'
import TaglineLoader from 'components/molecules/Loader'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Logout: FC = () => {
  const navigate = useNavigate()

  useQuery(['logout'], logoutUserFn, {
    onSuccess: () => {
      localStorage.clear()
      navigate('/login')
    },
    onError: () => {
      localStorage.clear()
      navigate('/login')
    },
  })

  return (
    <Container>
      <TaglineLoader />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`

export { Logout }

import { Contact } from '@types'
import { parseInfoField } from 'utils/parseInfoField'

export const prepareContactsCsvData = (data: Contact[]) =>
  data.map(({ firstname, lastname, info, identity, visible, i_userid }) => {
    const { role, cabinet } = parseInfoField(info)

    return {
      firstname,
      lastname,
      role,
      cabinet,
      identity,
      visibility: visible ? 'Visible' : 'Hidden',
      id: i_userid || null,
    }
  })

import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getCustomersFn, getFeatureGroupsFn, updateCustomerFn } from 'api'
import { Controller, useForm } from 'react-hook-form'
import { BlackBoldParagraph, GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import Loader from 'components/molecules/Loader'
import { useParams } from 'react-router-dom'
import { useUserContext } from 'contexts/UserContext'
import { Switch } from '../../../components/molecules/Switch'


interface FormProps {
  featuregroup: number
  debug: boolean
  blocked: boolean
  hidden_settings: boolean
  system_logs: boolean
  enable_webrtc_logger: boolean
}


const EditUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const params = useParams<{ id: string }>()
  const customer_id = +params.id!
  const queryClient = useQueryClient()
  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: selectedCustomer } = useQuery(['customers'], getCustomersFn, {
    select: (data) => data.filter(({ i_customer }) => i_customer === customer_id)?.[0],
  })

  const { data: featureGroupsOptions } = useQuery(['feature-groups', i_realm], getFeatureGroupsFn, {
    select: (data) => data.map(({ name, i_featuregroup }) => ({ label: name, value: i_featuregroup })),
  })

  const {
    control,
    formState: { isValid, isDirty },
    handleSubmit,
  } = useForm<FormProps>()

  const { mutate: updateCustomer } = useMutation(updateCustomerFn, {
    onSuccess: () => {
      onClose()
      queryClient.invalidateQueries(['users'])
      queryClient.invalidateQueries(['customers'])
    },
    onError: () => {
      toast.error(t('modal.editUser.editError'))
    },
  })

  const onSubmit = ({ featuregroup, debug, blocked, hidden_settings, system_logs, enable_webrtc_logger }: FormProps) => {
    updateCustomer({
      i_customer: customer_id,
      i_featuregroup: featuregroup,
      debug: debug ? 1 : 0,
      blocked: blocked ? 1 : 0,
      hidden_settings: hidden_settings ? 1 : 0,
      system_logs:  system_logs ? 1 : 0,
      enable_webrtc_logger: enable_webrtc_logger ? 1 : 0,
    })
  }

  if (!selectedCustomer) {
    return <Loader height="30vh" />
  }

  return (
    <ModalView title={t('modal.editUser.title')} style={{ maxWidth: '400px' }} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>

          <div>
            <Field>
              <Controller
                name="blocked"
                control={control}
                defaultValue={Boolean(selectedCustomer.blocked)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('common.blocked')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
              <div>
                <Controller
                  name="debug"
                  control={control}
                  defaultValue={Boolean(selectedCustomer.debug)}
                  render={({ field: { value, onChange } }) => (
                    <div
                     style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.debug')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
            </Field>

            <Field>
              <Controller
                name="hidden_settings"
                control={control}
                defaultValue={Boolean(selectedCustomer.hidden_settings)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('common.hidden_settings')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
              <div>
                <Controller
                  name="system_logs"
                  control={control}
                  defaultValue={Boolean(selectedCustomer.system_logs)}
                  render={({ field: { value, onChange } }) => (
                    <div
                      style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                    >
                      <BlackBoldParagraph text={t('common.system_logs')} />
                      <Switch checked={value} onToggle={onChange} />
                    </div>
                  )}
                />
              </div>
            </Field>

            <Field>
              <Controller
                name="enable_webrtc_logger"
                control={control}
                defaultValue={Boolean(selectedCustomer.enable_webrtc_logger)}
                render={({ field: { value, onChange } }) => (
                  <div
                    style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                  >
                    <BlackBoldParagraph text={t('common.enable_webrtc_logger')} />
                    <Switch checked={value} onToggle={onChange} />
                  </div>
                )}
              />
              <div>
                &nbsp;
              </div>
            </Field>

            <GreyParagraph2
              style={{ marginLeft: '15px', marginBottom: '5px' }}
              size="11"
              text={t('common.featureGroup')}
            />
            <Controller
              name="featuregroup"
              defaultValue={selectedCustomer.i_featuregroup}
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, onChange, value, ...props } }) => {
                return (
                  <ReactSelect
                    value={featureGroupsOptions?.filter((g) => g.value === value)}
                    onChange={({ value }) => {
                      onChange(value)
                    }}
                    isSearchable={false}
                    options={featureGroupsOptions}
                    placeholder={t('placeholder.chooseFeatureGroup')}
                    {...props}
                  />
                )
              }}
            />
          </div>

          <Button
            style={{ alignSelf: 'flex-end', marginTop: '12px' }}
            appearance="blue"
            type="submit"
            disabled={!isDirty || !isValid}
          >
            {t('modal.editUser.edit')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const Field = styled.div`
  display: flex;
  margin-bottom: 16px;
  flex-flow: wrap;
  gap: 12px;
  & > * {
    flex: 1;
    //min-width: 180px;
  }
`

export default EditUser

import axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { toast } from 'react-toastify'
import mem from 'mem'
import { LoginResponse } from '@types'
import { getErrorMessageByStatus } from 'utils/error'

const BASE_URL = process.env.REACT_APP_API_URL
const AXIOS_CONFIG = {
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
}
const api = axios.create(AXIOS_CONFIG)

api.interceptors.request.use(
  // @ts-ignore
  (config: AxiosRequestConfig) => {
    const token = localStorage.getItem('token')

    if (token) {
      config.headers!['Authorization'] = `Bearer ${JSON.parse(token)}`
    }

    return config
  },
  async (error) => {
    return Promise.reject(error)
  }
)

const refreshToken = async (token: string, refreshToken: string) => {
  try {
    const response = await axios.post<LoginResponse>(
      'auth/refresh',
      { refreshToken: JSON.parse(refreshToken) },
      {
        ...AXIOS_CONFIG,
        headers: {
          Authorization: `Bearer ${JSON.parse(token)}`,
        },
      }
    )

    const { refresh_token, access_token } = response.data

    localStorage.setItem('token', JSON.stringify(access_token))
    localStorage.setItem('refresh_token', JSON.stringify(refresh_token))
    return response.status
  } catch (error) {
    console.log('error', error)
  }
}

const memRefreshToken = mem(refreshToken)

api.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  async (error: AxiosError<{ reason?: string }>) => {
    const { response } = error
    const message = response?.data?.reason

    if (response?.status !== 401) {
      toast.error(getErrorMessageByStatus(response?.status), {
        toastId: 'interceptor-error',
      })
    }

    if (response?.status === 401) {
      const token = localStorage.getItem('token')
      const refreshToken = localStorage.getItem('refresh_token')

      if (refreshToken && token) {
        const originalRequest = error.config
        const status = await memRefreshToken(token, refreshToken)

        if (status === 200) {
          return api(originalRequest!)
        }
      }
      toast.error(message, { toastId: 'interceptor-no-auth' })
      localStorage.clear()
      document.location.href = '/login'
    }

    return Promise.reject(error)
  }
)

export * from './methods'
export default api

import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { Page, PageHeader } from 'components/organisms/layout'
import styled from 'styled-components'

const Metrics: FC = () => {
  const [t] = useTranslation()

  return (
    <Page title={t('page.metrics.title')}>
      <PageHeader title={t('page.metrics.title')} />
      <Wrapper>
        <iframe
          src="https://stats.stage.tagline.pt/public-dashboards/ab3e77dc999b4c7dad75ce7e4dfe0fd4?orgId=1"
          width="100%"
          height="860px"
          frameBorder="0"
          title="stats"
        />
      </Wrapper>
    </Page>
  )
}

const Wrapper = styled.div`
  display: flex;
  overflow: auto;
  max-height: calc(100vh - 160px);
`

export default Metrics

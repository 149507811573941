import { t } from 'i18next'

export const getErrorMessageByStatus = (status?: number) => {
  switch (status) {
    case 403:
      return t('error.403')
    case 404:
      return t('error.404')
    default:
      return t('error.default')
  }
}

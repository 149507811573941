//@ts-nocheck
import { BroadcastResponse } from '@types'
import { sortByDate } from 'utils/array'
import { BroadcastWithMessage } from './Broadcast'

export const formatBroadcastData = (data: BroadcastResponse[]) => {
  const combinedByGroupKey = data.reduce((acc, { group_key, user_id, message, channel, created, id }) => {
    const channelName = channel?.name
    const messageText = message.body

    return group_key
      ? {
          ...acc,
          [group_key]: {
            user_ids: acc?.[group_key]?.user_ids ? [...acc[group_key].user_ids, user_id] : [user_id],
            messageText,
            channelName,
            created,
            id,
          },
        }
      : acc
  }, {})

  const formattedData = Object.values(combinedByGroupKey)
    .map(({ user_ids, ...rest }) => ({
      users: user_ids.join(', '),
      ...rest,
    }))
    .sort((a, b) => sortByDate(a.created, b.created)) as BroadcastWithMessage[]

  return formattedData
}

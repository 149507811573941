import React from 'react'
import { useTranslation } from 'react-i18next'
import { NavLink as RouterLink } from 'react-router-dom'
import styled from 'styled-components'
import NavHeader from './molecules/NavHeader'
import NavItem from './molecules/NavItem'
import { MODALS } from '../Modal'
import { Dropdown } from 'components/molecules/Dropdown'
import { IconButton } from 'components/molecules/Button'
import Icon from 'components/atoms/Icon'
import { MenuItem, Popup } from 'components/molecules/Popup'
import Paragraph from 'components/atoms/Paragraph'
import Tooltip from 'components/atoms/Tooltip'
import { useUserContext } from 'contexts/UserContext'
import { isInternalDomain, isTagline } from 'utils/location'
import { useQuery } from 'react-query'
import { getVersionFn } from 'api'

export default function SideNav() {
  const [t] = useTranslation()
  const { state, isSuperAdmin } = useUserContext()
  const { data: app } = useQuery(['version'], getVersionFn)

  const username = state.admin?.username

  const { i18n } = useTranslation()
  const { language: currentLanguage, changeLanguage } = i18n

  return (
    <Container>
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', overflow: 'auto' }}>
          <NavHeader />
          <Link to="/users" data-nav-item>
            <NavItem name={t('navBar.users')} iconName="uil:user" />
          </Link>
          <Link to="/devices" data-nav-item>
            <NavItem name={t('navBar.devices')} iconName="uil:mobile-android-alt" />
          </Link>
          <Link to="/contacts" data-nav-item>
            <NavItem name={t('navBar.contacts')} iconName="uil:user-square" />
          </Link>
          <Link to="/broadcast" data-nav-item>
            <NavItem name={t('navBar.broadcast')} iconName="uil:channel" />
          </Link>
          <Link to="/info-messages" data-nav-item>
            <NavItem name={t('navBar.infoMessages')} iconName="uil:comment-info" />
          </Link>
          <Link to="/feature-groups" data-nav-item>
            <NavItem name={t('navBar.featureGroups')} iconName="uil:credit-card" />
          </Link>
          <Link to="/apps" data-nav-item>
            <NavItem name={t('navBar.apps')} iconName="uil:apps" />
          </Link>
          {isTagline && (
            <Link to="/metrics" data-nav-item>
              <NavItem name={t('navBar.metrics')} iconName="uil:graph-bar" />
            </Link>
          )}
          <Link to="/log" data-nav-item>
            <NavItem name={t('navBar.activityLog')} iconName="uil:equal-circle" />
          </Link>
          <Link to="/admin" data-nav-item>
            <NavItem name={t('navBar.admins')} iconName="uil:star" />
          </Link>
          {isInternalDomain() && isSuperAdmin && (
            <Link to="/realms" data-nav-item>
              <NavItem name={t('navBar.realms')} iconName="gridicons:domains" />
            </Link>
          )}
        </div>
        <div>
          <Footer>
            <Dropdown
              button={
                <IconButton>
                  <Icon icon={currentLanguage === 'en' ? 'twemoji:flag-united-kingdom' : 'twemoji:flag-portugal'} />
                </IconButton>
              }
              position="top"
            >
              <Popup>
                <MenuItem value="pt" icon={<Icon icon="twemoji:flag-portugal" />} onClick={() => changeLanguage('pt')}>
                  <Paragraph text="Português" />
                </MenuItem>
                <MenuItem
                  value="en"
                  icon={<Icon icon="twemoji:flag-united-kingdom" />}
                  onClick={() => changeLanguage('en')}
                >
                  <Paragraph text="English" />
                </MenuItem>
              </Popup>
            </Dropdown>
            <Version to={`?modal=${MODALS.changelog}`}>{app?.version}</Version>
          </Footer>
          <Breadcrumbs>
            <Breadcrumb to={`?modal=${MODALS.about}`}>{t('navBar.about')}</Breadcrumb>
            <Tooltip message={t('tooltip.loggedAs', { username })} disabled={!username}>
              <Breadcrumb to="/logout">{t('navBar.logout')}</Breadcrumb>
            </Tooltip>
          </Breadcrumbs>
        </div>
      </div>
    </Container>
  )
}

/////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////

const Container = styled.div`
  display: flex;
  min-width: 200px;
  min-height: 100vh;
  background-color: white;
  padding-top: 15px;
  z-index: 1;

  & [data-sub-nav-item]:not(:last-child) {
    padding-bottom: 10px;
  }
  @media screen and (max-width: 856px) {
    min-width: 56px;
    width: 56px;
  }
`

const Link = styled(RouterLink)`
  text-decoration: none;
  display: block;

  padding: 12px 0;
  transition: all 0.5s;
  &.active {
    background: #62b0db1a;
    * {
      color: #1c658e;
      fill: #1c658e;
    }
  }

  &:hover {
    background: #aad3e910;
  }
  @media screen and (max-width: 856px) {
    overflow: hidden;
  }
`

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 17px;
  flex-wrap: wrap;

  @media screen and (max-width: 856px) {
    justify-content: center;
    gap: 12px;
  }
`

const Breadcrumbs = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  border-top: 2px solid #eee;
  width: 100%;
  bottom: 0px;
  padding: 18px;
  @media screen and (max-width: 856px) {
    flex-direction: column;
    padding: 18px 6px;
  }
`
const Breadcrumb = styled(RouterLink)`
  font-size: 14px;
  color: #a9a9a9;
  transition: all 0.2s;
  text-decoration: underline;
  text-decoration-color: #ffffff3f;
  &:hover {
    cursor: pointer;
    color: #959595;
    text-decoration-color: #959595;
  }
`
const Version = styled(Breadcrumb)`
  font-size: 12px;
`

import React, { useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import cn from 'classnames'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { useUserContext } from 'contexts/UserContext'
import { Gap10 } from 'components/atoms/Gap'
import Headline from 'components/atoms/Headline'
import Icon from 'components/atoms/Icon'
import Input from 'components/atoms/Input'
import { Span } from 'components/atoms/Span'
import Button from 'components/molecules/Button'
import { IconButton } from 'components/molecules/Button'
import { LoginRequest } from '@types'
import { loginUserFn } from 'api'
import { useTranslation } from 'react-i18next'

const schema = z.object({
  username: z.string().min(3),
  password: z.string().min(8),
})

interface UserCredentials {
  username: string
  password: string
}

export default function LoginForm() {
  const [t] = useTranslation()
  const userContext = useUserContext()
  const navigate = useNavigate()

  const {
    control,
    handleSubmit,
    setError,
    formState: { isValid, errors },
  } = useForm<UserCredentials>({ resolver: zodResolver(schema) })

  const [passVisible, setPassVisible] = useState<boolean>(false)

  const { mutate: loginUser } = useMutation((userData: LoginRequest) => loginUserFn(userData), {
    onSuccess: ({ access_token, refresh_token }) => {
      userContext.setToken(access_token)
      userContext.setRefreshToken(refresh_token)
      toast.dismiss()
      navigate('/')
    },
    onError: () => {
      setError('password', { type: 'validate', message: 'Invalid username or password' })
      localStorage.clear()
    },
  })

  const onSubmit = ({ username, password }: UserCredentials) => {
    loginUser({ username, password })
  }

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Headline size="H2" text="Login" />
      <FieldContainer>
        <Span size="12" color="#747474">
          {t('common.username')}
        </Span>
        <Controller
          name="username"
          defaultValue=""
          rules={{ required: true }}
          control={control}
          render={({ field: { ref, ...props } }) => (
            <Input placeholder={t('common.username')!} type="text" {...props} />
          )}
        />
      </FieldContainer>

      <FieldContainer>
        <Span size="12" color="#747474">
          {t('common.password')}
        </Span>
        <PasswordFieldContainer>
          <Controller
            name="password"
            defaultValue=""
            rules={{ required: true }}
            control={control}
            render={({ field: { ref, ...props } }) => (
              <Input type={passVisible ? 'text' : 'password'} placeholder={t('common.password')!} {...props} />
            )}
          />
          <ShowHidePassButton onClick={() => setPassVisible((prev) => !prev)}>
            {passVisible ? <Icon icon="uil:eye" color="#747474" /> : <Icon icon="uil:eye-slash" color="#747474" />}
          </ShowHidePassButton>
        </PasswordFieldContainer>
      </FieldContainer>

      <ErrorField className={cn({ isError: Boolean(Object.keys(errors).length) })}>
        {errors.password?.message || errors.username?.message}
      </ErrorField>

      <LoginButton icon="uil:arrow-right" type="submit" appearance="blue" disabled={!isValid}>
        Login
      </LoginButton>
      <Gap10 />
    </Form>
  )
}

const Form = styled.form`
  display: grid;
  grid: 60px 60px 60px 40px / auto;
  gap: 12px;
  padding: 70px 60px 0;
`

const FieldContainer = styled.div`
  display: grid;
  gap: 5px;
  grid: auto 40px 1fr / auto;

  > span:first-child {
    padding-left: 10px;
  }
  input {
    background-color: #f0f8ff;
    border: none;
    outline: none;
  }
`

const ErrorField = styled.div`
  &.isError {
    display: flex;
    justify-content: center;
    border-top: 1px solid #e95b5b;
    align-items: center;
    font-size: 12px;
    color: #e95b5b;
    margin: 0 18px;
  }
`

const PasswordFieldContainer = styled.div`
  position: relative;
  input {
    padding-right: 40px;
  }
`

const ShowHidePassButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
`

const LoginButton = styled(Button)`
  width: 100%;
  justify-content: center;
`

import { AdminUser, Realm } from '@types'
import { useLocalStorage } from 'hooks/useLocalStorage'
import React, { createContext, useReducer } from 'react'
import { FakeRealm, Roles } from 'utils/consts'

type State = {
  admin: AdminUser | null
  realm: Realm
}

type Dispatch = (action: Action) => void

type Action = {
  type: string
  payload: AdminUser | Realm | null
}

interface UserContextValue {
  token?: string
  savedRealm?: Realm
  refreshToken?: string
  setToken: (token: string) => void
  setSavedRealm: (realm: Realm) => void
  setRefreshToken: (token: string) => void
  isSuperAdmin: boolean

  state: State
  dispatch: Dispatch
}

type UserContextProviderProps = { children: React.ReactNode }

const initialContextValue = {
  setToken() {},
  setRefreshToken() {},
  setSavedRealm() {},
  dispatch() {},
  isSuperAdmin: false,
  state: {
    admin: null,
    realm: FakeRealm,
    version: null,
  },
}

export const UserContext = createContext<UserContextValue>(initialContextValue)

export const UserContextProvider = ({ children }: UserContextProviderProps) => {
  const [token, setToken] = useLocalStorage('token')
  const [savedRealm, setSavedRealm] = useLocalStorage('realm')
  const [refreshToken, setRefreshToken] = useLocalStorage('refresh_token')
  const [state, dispatch] = useReducer(stateReducer, initialState)

  const values = {
    state,
    dispatch,
    token,
    setToken,
    savedRealm,
    setSavedRealm,
    refreshToken,
    setRefreshToken,
    isSuperAdmin: state.admin?.type === Roles.SuperAdmin,
  }
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>
}

const initialState: State = {
  admin: null,
  realm: FakeRealm,
}

const stateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_ADMIN': {
      return {
        ...state,
        admin: action.payload as AdminUser | null,
      }
    }
    case 'SET_REALM': {
      return {
        ...state,
        realm: action.payload as Realm,
      }
    }
    default: {
      throw new Error(`Unhandled action type`)
    }
  }
}

export const useUserContext = () => {
  const context = React.useContext(UserContext)

  if (context) {
    return context
  }

  throw new Error(`useUserContext must be used within a UserContextProvider`)
}

import React, { FC, useMemo } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { AdminUserCredentials, Realm, SelectOption } from '@types'
import { toast } from 'react-toastify'
import { createAdminFn } from 'api'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useTranslation } from 'react-i18next'
import { FormField } from 'components/molecules/Form/FormField'
import { adminPasswordRegex } from 'utils/consts'
import { adminTypesOptions } from '..'

const schema = z
  .object({
    username: z.string().min(1, { message: 'Required' }),
    type: z.string().startsWith('ADMINISTRATOR', { message: 'Choose admin type' }),
    password: z.string().regex(adminPasswordRegex, {
      message: 'Min 10 chars, uppercase, lowercase and digit',
    }),
    password_confirmation: z.string().regex(adminPasswordRegex, {
      message: 'Min 10 chars, uppercase, lowercase and digit',
    }),
    realms: z.object({ value: z.number(), label: z.string() }).array(),
  })
  .refine((data) => data.password === data.password_confirmation, {
    message: "Passwords don't match",
    path: ['password_confirmation'],
  })

const AddUser: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    setError,
  } = useForm<AdminUserCredentials>({ resolver: zodResolver(schema) })
  const realms = useQueryClient().getQueryData('realms') as Realm[]

  const realmsOptions = useMemo(
    () => (realms || []).map((realm) => ({ value: realm.i_realm, label: realm.name })),
    [realms]
  )

  const { mutate: createAdmin } = useMutation((credentials: AdminUserCredentials) => createAdminFn(credentials), {
    onSuccess: ({ username, type }) => {
      queryClient.invalidateQueries('admins')
      toast.success(t('modal.addAdmin.addSuccess', { type, username }))
      onClose()
    },
    onError: () => {
      setError('password', { type: 'validate', message: 'Min 10 chars, uppercase, lowercase and digit' })
    },
  })

  const onSubmit = ({ realms: realmOptions, ...credentials }: AdminUserCredentials) => {
    const realms = (realmOptions as SelectOption[]).map(({ value }) => value)
    createAdmin({ ...credentials, realms })
  }

  return (
    <ModalView title={t('modal.addAdmin.add')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="username"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions type="text" caption={t('common.username')} {...props} />
                </FormField>
              )}
            />
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.type')} />
              <Controller
                name="type"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <FormField error={errors[name]?.message}>
                      <ReactSelect
                        value={adminTypesOptions.filter((g) => g.value === value)}
                        onChange={({ value }) => {
                          onChange(value)
                        }}
                        options={adminTypesOptions}
                        placeholder={t('modal.addAdmin.choose')}
                        {...props}
                      />
                    </FormField>
                  )
                }}
              />
            </div>
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.realms')} />
              <Controller
                name="realms"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <FormField error={errors[name]?.message}>
                      <ReactSelect
                        placeholder={t('placeholder.chooseRealm')}
                        value={value}
                        onChange={onChange}
                        options={realmsOptions}
                        isMulti
                      />
                    </FormField>
                  )
                }}
              />
            </div>
          </Field>

          <Field>
            <Controller
              name="password"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions
                    autoComplete="new-password"
                    type="password"
                    caption={t('common.password')}
                    {...props}
                  />
                </FormField>
              )}
            />
            <Controller
              name="password_confirmation"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <FormField error={errors[name]?.message}>
                  <InputWithCaptions type="password" caption={t('common.passwordConfirm')} {...props} />
                </FormField>
              )}
            />
          </Field>

          <Button style={{ alignSelf: 'flex-end' }} appearance="blue" type="submit" disabled={!isDirty}>
            {t('modal.addAdmin.add')}
          </Button>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    flex: 1;
  }
`

export default AddUser

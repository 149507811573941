export const getPlatformLabel = (bundle: string) => {
  if (bundle.includes('ios')) {
    return 'iOS app'
  }
  if (bundle.includes('android')) {
    return 'Android app'
  }

  return 'Desktop'
}

import React, { FC, memo } from 'react'
import styled from 'styled-components'
import cn from 'classnames'
import Select, { StylesConfig } from 'react-select'

const StyledReactSelect = styled.div`
  display: flex;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
  }

  .react-select__input {
    display: flex;
    align-items: center;
  }

  .react-select__placeholder {
    color: ${({ theme }) => theme.colors.black};
  }
`

const stylesForSelect = (isMulti: boolean, isShrink: boolean): StylesConfig => ({
  container: (styles: any) => ({
    width: '100%',
    ...styles,
  }),
  placeholder: () => ({
    position: 'absolute',
    paddingLeft: '8px',
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.32)',
  }),
  control: (_: any, { isFocused }: any) => ({
    display: 'flex',
    minHeight: isShrink ? '32px' : '48px',
    minWidth: '100px',
    paddingRight: '8px',
    backgroundColor: '#FFFFFF',
    boxShadow: isFocused ? '0px 1px 3px rgba(0, 0, 0, 0.64)' : '0px 1px 3px rgba(0, 0, 0, 0.24)',
    border: 'none',
    borderRadius: '8px',
    ':hover': {
      cursor: 'text',
      boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.24)',
    },
  }),
  input: (styles) => ({
    ...styles,
    margin: '0',
    padding: '0',
    paddingLeft: '8px',
  }),
  valueContainer: (styles) =>
    isMulti
      ? { ...styles, maxHeight: 300, overflow: 'auto' }
      : {
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1,
          padding: '2px',
          overflow: 'visible',
        },
  dropdownIndicator: (_: any, { isFocused }: any) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fill: isFocused ? '#000000' : 'rgba(0, 0, 0, 0.64)',
    width: '20px',
    height: '20px',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  singleValue: () => ({
    paddingLeft: '8px',
    fontSize: '14px',
    width: '100%',
  }),
  multiValue: (styles: any) => ({
    ...styles,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0 0 0 8px',
    marginRight: '2px',
    borderRadius: '8px',
    backgroundColor: 'rgba(0, 150, 136, 0.08)',
  }),
  multiValueLabel: () => ({
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: '#009688',
  }),
  multiValueRemove: () => ({
    display: 'flex',
    alignItems: 'center',
    marginLeft: '4px',
    padding: '4px',
    borderRadius: '8px',
    fill: '#009688',
    cursor: 'pointer',
    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  }),
  menu: (styles: any) => ({
    ...styles,
    border: 'none',
    borderRadius: '8px',
    boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.32)',
    zIndex: 999,
  }),
  option: (styles: any, { isFocused, isSelected, isDisabled }: any) => ({
    ...styles,
    height: 'auto',
    padding: '6px 12px',
    borderRadius: '4px',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '20px',
    color: isSelected ? 'rgba(0, 0, 0, 0.1)' : '#000000',
    cursor: isSelected ? 'not-allowed' : isFocused ? 'pointer' : 'default',
    pointerEvents: isDisabled ? 'none' : 'auto',
    backgroundColor: isDisabled
      ? 'rgba(0, 0, 0, 0.1)'
      : isSelected
      ? 'rgba(0, 0, 0, 0.01)'
      : isFocused
      ? 'rgba(0, 0, 0, 0.04)'
      : 'inherit',
    overflowX: 'auto',
    overflowY: 'hidden',
  }),
})

export interface OptionType {
  label: string
  value: string | number
}

interface ReactSelectProps {
  options?: OptionType[]
  placeholder: string
  value?: any
  name?: string
  className?: string
  onBlur?: VoidFunction
  disabled?: boolean
  isSearchable?: boolean
  isLoading?: boolean
  isMulti?: boolean
  isShrink?: boolean
  onChange: (newValue: any) => void
  getOptionValue?: (value: any) => string
  getOptionLabel?: (value: any) => string
}

const ReactSelect: FC<ReactSelectProps> = ({
  className,
  disabled,
  isSearchable = true,
  options = [],
  isMulti,
  isShrink,
  ...props
}) => {
  return (
    <StyledReactSelect className={cn(className, { disabled })}>
      <Select
        styles={stylesForSelect(!!isMulti, !!isShrink)}
        isClearable={false}
        isSearchable={isSearchable}
        isDisabled={disabled}
        options={options}
        isMulti={isMulti}
        {...props}
      />
    </StyledReactSelect>
  )
}

export default memo(ReactSelect)

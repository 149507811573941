import { Icon } from '@iconify/react'
import { ReactNode } from 'react'
import styled from 'styled-components'

const Filter = ({ children }: { children: ReactNode }) => {
  return (
    <Container>
      <FilterHeader>
        <Icon icon="uil:filter" />
        <FilterTitle>Filters</FilterTitle>
      </FilterHeader>
      <FiltersContent>{children}</FiltersContent>
    </Container>
  )
}

const Container = styled.div`
  width: 210px;
`

const FilterTitle = styled.span`
  margin-left: 8px;
  font-size: 14px;
  font-weight: 600;
`

const FiltersContent = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: calc(100vh - 62px);
`

const FilterHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 30px 16px 10px 16px;
  border-bottom: 1px solid #ccc;
`

export default Filter

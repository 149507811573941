import { Icon } from '@iconify/react'
import { IconButton } from 'components/molecules/Button'
import styled from 'styled-components'

const Wrapper = styled.div.attrs((props: { isGroup: boolean }) => props)`
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:not(:first-child) {
    margin-top: ${(props) => (props.isGroup ? `14px` : 0)};
  }
`

const Option = styled.div.attrs((props: { isGroup: boolean; isSelected: boolean }) => props)`
  display: flex;
  font-size: ${(props) => (props.isGroup ? `13px` : `12px`)};
  font-weight: ${(props) => (props.isGroup ? `600` : `400`)};
  line-height: 16px;

  transition: all 0.3s;
  color: ${(props) => (props.isSelected ? `#2e82ff` : `black`)};

  cursor: ${(props) => (props.isSelected ? 'default' : `pointer`)};
  &:hover {
    color: #2e82ff;
    filter: contrast(0.3);
  }
`

const FilterText = styled.div``
const FilterCount = styled.div`
  color: #a5a5a5;
  margin-left: 5px;
`

export const FilterOption = ({
  filterName,
  count,
  isSelected,
  isGroup,
  onClick,
}: {
  filterName: string
  count: number
  onClick: () => void
  isSelected: boolean
  isGroup?: boolean
}) => {
  return (
    <Wrapper isGroup={isGroup}>
      <Option isGroup={isGroup} isSelected={isSelected} onClick={onClick}>
        <FilterText>{filterName}</FilterText>
        <FilterCount>({count})</FilterCount>
      </Option>
      {isSelected && (
        <IconButton
          style={{ width: 18, height: 18, margin: '-4px 0 -6px 4px', padding: 2 }}
          size="default"
          onClick={onClick}
        >
          <Icon icon="uil:multiply" />
        </IconButton>
      )}
    </Wrapper>
  )
}

import Headline from 'components/atoms/Headline'
import React from 'react'
import styled, { useTheme } from 'styled-components'

interface Props {
  text: string
}

export default function Header({ text }: Props) {
  const theme = useTheme()
  return <HeadlineText text={text} color={theme.colors.black} size="H4" />
}

const HeadlineText = styled(Headline)`
  font-weight: bold;
  user-select: none;
  font-size: 11px;
`

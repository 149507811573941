import styled from 'styled-components'

export const FlexTableContainer = styled.div`
  ${'' /* These styles are suggested for the table fill all available space in its containing element */}
  display: block;
  ${'' /* These styles are required for a horizontaly scrollable table overflow */}
  /* overflow: auto; */
  overflow: visible;

  .table {
    border-spacing: 0;
    background-color: rgba(185, 185, 185, 0.1);
    border-radius: 4px 4px 0 0;
    /* box-shadow: 0 3px 7px ${(p) => p.theme.colors.lightGrey}; */

    .thead {
      ${'' /* These styles are required for a scrollable body to align with the header properly */}
      overflow-y: auto;
      overflow-x: hidden;
      position: sticky;
      top: 0;
      background: white;
      border-radius: 4px 4px 0 0;
      z-index: 1;
      border-bottom: 1px solid #a6a6a6;
    }

    .tbody {
      ${'' /* These styles are required for a scrollable table body */}
      /* overflow-y: scroll;
      overflow-x: hidden; */
      .tr:nth-child(odd) {
        background-color: rgba(255, 255, 255);
      }
    }

    .th {
      display: flex;
      align-items: center;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
      overflow: auto;
      .header-content {
        display: flex;
        align-items: center;
      }
    }

    .td {
      display: flex;
      align-items: center;
      &:last-child {
        justify-content: flex-end;
      }
    }

    .th,
    .td {
      padding: 5px 10px;

      ${
        '' /* In this example we use an absolutely position resizer,
       so this is required. */
      }
      position: relative;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .td:last-child {
      overflow: visible;
    }

    .th:not(:last-child):hover {
      .resizer {
        right: 0;
        background: #edf1f4;
        width: 1px;
        height: 20px;
        position: absolute;
        top: 25%;
        z-index: 1;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action :none;

        &.isResizing {
          background: blue;
        }
        &:hover {
          background: #a7a9bd;
        }
      }
    }
  }
`

export const Container = styled.div`
  .table {
    background: transparent;
    .tbody {
      height: calc(100vh - 232px);
      overflow: auto;
      .tr {
        min-height: 40px;
      }
      .sub-tr > .td:not(:first-child) {
        margin-bottom: 2px;
        background: #b9b9b944;
      }
      .row-container:nth-child(even) {
        .tr {
          background-color: rgba(210, 210, 210, 0.184);
        }
      }
      .row-container.suspended p,
      .row-container.suspended span {
        opacity: 0.5;
      }
    }
  }

  ::-webkit-scrollbar {
    display: none;
  }
  footer {
    display: flex;
    margin-top: 8px;
    align-items: center;
    justify-content: center;
  }

  .popup-button {
    display: flex;
    align-items: center;
    padding: 4px 8px;
    &:hover {
      background: #f5f5f5;
    }
  }
`

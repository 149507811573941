import React from 'react'
import { Toast } from 'components/molecules/Toast'
import styled from 'styled-components'
import SideNav from 'components/organisms/navigation'
import { Outlet } from 'react-router-dom'
import Modals from 'components/organisms/Modal'
import { ConfirmDialog } from 'components/organisms/ConfirmDialog/ConfirmDialog'
import { useQuery } from 'react-query'
import { getMeFn, getRealmsFn } from 'api'
import { useUserContext } from 'contexts/UserContext'

export default function App() {
  const { dispatch, savedRealm } = useUserContext()

  useQuery(['realms'], getRealmsFn, {
    onSuccess: (data) => {
      dispatch({ type: 'SET_REALM', payload: savedRealm ? savedRealm : data[0] })
    },
  })

  useQuery(['me'], getMeFn, {
    onSuccess: (data) => {
      dispatch({ type: 'SET_ADMIN', payload: data })
    },
  })

  return (
    <Container>
      <Toast />
      <Modals />
      <ConfirmDialog />
      <SideNav />
      <Outlet />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  height: 100vh;
`

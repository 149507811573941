import dayjs from 'dayjs'
import { UserWithFG } from './types'
import { FeatureGroup } from '@types'
import { parseInfoField } from 'utils/parseInfoField'

export const prepareUsersCsvData = (data: UserWithFG[], featureGroups?: FeatureGroup[]) =>
  data.map(({ devices, created, name, activation_created, featureGroup, id, last_login, roster }) => {
    const { role, cabinet } = parseInfoField(roster?.info)

    return {
      created: dayjs(created).format('YYYY-MM-DD HH:MM'),
      OS: devices?.[0]?.deviceos,
      version: devices?.[0]?.clientversion,
      name,
      role,
      cabinet,
      activation_created: activation_created ? dayjs(activation_created).format('YYYY-MM-DD HH:MM') : null,
      i_featuregroup: featureGroups?.find(({ name }) => name === featureGroup)?.i_featuregroup || null,
      id,
      last_login: last_login ? dayjs(last_login).format('YYYY-MM-DD HH:MM') : null,
    }
  })

import React, { FC, MouseEvent, useCallback, useState } from 'react'
import { Icon } from '@iconify/react'
import { deleteInfoMessageFn, getBundleMappingsFn, getInfoMessagesFn, updateInfoMessageFn } from 'api'
import { LoadingPage } from 'components/molecules/Loader'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { LinkTo } from 'components/atoms/Pointer'
import Button from 'components/molecules/Button'
import { MODALS } from 'components/organisms/Modal'
import MessageCard from './components/MessageCard'
import { InfoMessage } from '@types'
import { useConfirm } from 'components/organisms/ConfirmDialog'
import { Page, PageHeader } from 'components/organisms/layout'
import { useTranslation } from 'react-i18next'
import { getPlatformLabel } from './utils'
import { useUserContext } from 'contexts/UserContext'

const InfoMessages: FC = () => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const { confirm } = useConfirm()
  const [editId, setEditId] = useState<number>()
  const { data: bundlesOptions } = useQuery(['bundle-mappings', i_realm], getBundleMappingsFn, {
    select: (data) =>
      data.map(({ clientbundleid }) => ({ label: getPlatformLabel(clientbundleid), value: clientbundleid })),
  })
  const { data: messages, isLoading } = useQuery(['info-messages', i_realm], getInfoMessagesFn)

  const { mutate: updateMessage } = useMutation(updateInfoMessageFn, {
    onSuccess: () => {
      toast.success(t('modal.editFeatureGroup.successEdit'))
      queryClient.invalidateQueries(['info-messages'])
    },
  })

  const { mutate: deleteMessage } = useMutation(deleteInfoMessageFn, {
    onSuccess: () => {
      toast.success(t('modal.editFeatureGroup.successDelete'))
      queryClient.invalidateQueries(['info-messages'])
    },
  })

  const handleSave = useCallback(
    ({
      button,
      buttonCancel,
      buttonIsPrimary,
      buttonUrl,
      ...values
    }: Partial<
      InfoMessage & { button?: string; buttonCancel?: string; buttonUrl?: string; buttonIsPrimary?: boolean }
    >) => {
      updateMessage({
        id: editId!,
        i_realm: i_realm,
        clientbundleid: values.clientbundleid || '-1',
        buttons: buttonUrl
          ? JSON.stringify([
              { type: 'openurl', label: button, url: buttonUrl, primary: buttonIsPrimary },
              { type: 'cancel', label: buttonCancel },
            ])
          : '',
        ...values,
      })
      setEditId(undefined)
    },
    [editId, updateMessage, i_realm]
  )

  const handleEdit = useCallback(
    (messageId: number) => (e: MouseEvent) => {
      e.preventDefault()
      setEditId(messageId)
    },
    []
  )

  const handleCancelEdit = () => setEditId(undefined)

  const handleDelete = async (messageId: number) => {
    const isConfirmed = await confirm(t('modal.editFeatureGroup.confirmDelete')!)
    if (isConfirmed) {
      deleteMessage(messageId)
    }
  }

  if (isLoading || !messages) {
    return <LoadingPage />
  }

  return (
    <Page title={t('page.infoMessages.title')}>
      <PageHeader
        title={t('page.infoMessages.title')}
        subtitle={messages?.length.toString()}
        actions={
          <LinkTo to={`/info-messages?modal=${MODALS.newInfoMessage}`}>
            <Button icon={<Icon icon="uil:plus" />} appearance="blue" style={{ marginLeft: '12px' }}>
              {t('page.infoMessages.newMessage')}
            </Button>
          </LinkTo>
        }
      />
      <Content>
        {messages.map((message) => (
          <MessageCard
            {...message}
            bundles={bundlesOptions}
            isEditing={message.id === editId}
            onEdit={handleEdit(message.id)}
            onCancel={() => handleCancelEdit()}
            onSave={handleSave}
            onDelete={() => handleDelete(message.id)}
            key={message.id}
          />
        ))}
      </Content>
    </Page>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  overflow: auto;
  padding: 32px;
  margin: 0 -32px;
  max-height: calc(100vh - 120px);
`

export default InfoMessages

import Icon from 'components/atoms/Icon'
import { BlackBoldParagraph } from 'components/atoms/Paragraph'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import React, { FC, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { IconButton } from './Button'

interface FilterProps {
  filter: React.ReactNode
  isFilterActive?: boolean
  onClearFilters?: () => void
}

const Filter: FC<FilterProps> = ({ filter, isFilterActive, onClearFilters }) => {
  const [t] = useTranslation()
  const [isActive, setIsActive] = useState(false)

  const handleClose = () => setIsActive(false)

  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, handleClose)

  return (
    <Container ref={ref}>
      <IconButton onClick={() => setIsActive((prev) => !prev)}>
        <Icon icon="uil:filter" color={isFilterActive ? '#5a91b0' : 'inherit'} />
      </IconButton>
      {isActive && (
        <Menu>
          <MenuHeader>
            <BlackBoldParagraph text={t('common.filterBy')} />
            {onClearFilters && (
              <IconButton onClick={onClearFilters}>
                <Icon icon="uil:filter-slash" />
              </IconButton>
            )}
          </MenuHeader>
          {filter}
        </Menu>
      )}
    </Container>
  )
}
const Container = styled.div`
  position: relative;
`

const Menu = styled.div`
  position: absolute;
  top: 28px;
  right: 0;
  min-width: max-content;
  border-radius: 8px;
  border: 1px solid #e7e7e7;
  background-color: #fff;
  z-index: 99;
  padding: 24px;
`

const MenuHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
`

export default Filter

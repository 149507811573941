import React, { FC } from 'react'
import { Row, TableInstance } from 'react-table'
import Loader from 'components/molecules/Loader'
import NoResultBox from 'components/molecules/NoResultBox/NoResultBox'
import SortChevron from 'components/organisms/Table/atoms/SortChevron'
import Pagination from 'components/organisms/Table/organisms/Pagination'
import { Container, FlexTableContainer } from './styles'

interface Props extends TableInstance<any> {
  isLoading: boolean
  initialFilter?: { [key: string]: any }
}

const Table: FC<Props> = ({
  isLoading,
  headerGroups,
  prepareRow,
  getTableProps,
  page,
  canPreviousPage,
  canNextPage,
  pageOptions,
  gotoPage,
  nextPage,
  previousPage,
  state: tableState,
  setGlobalFilter,
  initialFilter = {},
}) => {
  return (
    <Container>
      {!isLoading ? (
        <FlexTableContainer>
          <div className="table" {...getTableProps()}>
            <div className="thead">
              {headerGroups.map((headerGroup) => (
                <div {...headerGroup.getHeaderGroupProps()} className="tr">
                  {headerGroup.headers.map((column) => (
                    <div {...column.getHeaderProps()} className="th">
                      <div {...column.getSortByToggleProps()} className="header-content">
                        {column.render('Header')}

                        <SortChevron
                          isSorted={column.isSorted}
                          canSort={column.canSort}
                          isSortedDesc={column.isSortedDesc}
                        />
                      </div>
                      {column.canResize && (
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
            <div className="tbody">
              {page.length ? (
                page.map((row) => {
                  prepareRow(row)
                  return <SingleRow row={row} key={row.id} />
                })
              ) : (
                <NoResultBox clearSearch={() => setGlobalFilter(initialFilter)} />
              )}
            </div>
          </div>
        </FlexTableContainer>
      ) : (
        <Loader />
      )}
      {page.length && !isLoading ? (
        <footer>
          <Pagination
            gotoPage={gotoPage}
            nextPage={nextPage}
            pageOptions={pageOptions}
            previousPage={previousPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageIndex={tableState.pageIndex}
          />
        </footer>
      ) : null}
    </Container>
  )
}

function SingleRow({ row }: { row: Row<any> }) {
  return (
    <div className="row-container">
      <div {...row.getRowProps()} className="tr">
        {row.cells.map((cell) => {
          return (
            <div {...cell.getCellProps()} className="td">
              <div>{cell.render('Cell')}</div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Table

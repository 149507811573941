import { Control, Controller } from 'react-hook-form'
import { FeatureGroup } from '@types'
import { Switch } from 'components/molecules/Switch'
import { BlackBoldParagraph } from 'components/atoms/Paragraph'
import styled from 'styled-components'

const SwitchField = ({
  name,
  title,
  control,
}: {
  name: keyof FeatureGroup
  title: string
  control: Control<FeatureGroup, any>
}) => (
  <Container>
    <Controller
      name={name}
      control={control}
      render={({ field: { ref, value, onChange, ...rest } }) => (
        <Switch checked={Boolean(value)} onToggle={onChange} {...rest} />
      )}
    />
    <Title text={title} />
  </Container>
)

const Container = styled.div`
  display: flex;
  width: 220px;
  align-items: center;

  border-left: 1px solid #ccc;
  padding: 4px 12px;

  margin-left: -1px;
`

const Title = styled(BlackBoldParagraph)`
  margin-left: 4px;
`

export { SwitchField }

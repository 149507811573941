import React from 'react'
import { ThemeProvider } from 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: typeof otherThemeColors & typeof defaultThemeColors
  }
}

////////////////////////////////////////////////////////////////////////////////////////////

export const otherThemeColors = {
  black: '#353535',
  coolGrey1: '#F5F5F5',
  coolGrey2: '#747474',
  superCoolGrey: '#B9B9B9',
  plainWhite: '#FFFFFF',
  blueBonnent: '#5878FF',
  blueLight: 'rgba(10, 35, 251, 0.4)',
  lightGrey: 'rgba(167, 169, 189, 0.4)',
  tropicalGreen: '#1DC7B1',
  tropicalGreen30: 'rgba(29, 199, 177, 0.3)',
  grainYellow: '#F0BC37',
  camineRed: '#FF585E',
}

////////////////////////////////////////////////////////////////////////////////////////////

export type ColorSchemaType = typeof defaultThemeColors

export const defaultThemeColors = {
  primary: '#1C658E',
  secondary: '#FF585E',
  error: '#FF585E',
  warning: '#FFC430',
  success: '#5AAD94',
}

////////////////////////////////////////////////////////////////////////////////////////////
export const ThemeConfigContext = React.createContext({
  setColor(k: keyof typeof defaultThemeColors, v: string) {},
})

////////////////////////////////////////////////////////////////////////////////////////////

export function useThemeConfigContext() {
  return React.useContext(ThemeConfigContext)
}

////////////////////////////////////////////////////////////////////////////////////////////

export function AppThemeProvider(props: { children: React.ReactNode }) {
  const [themeColors, setThemeColors] = React.useState(defaultThemeColors)

  // const { data: serverColorSchema } = useColorSchemes();
  // React.useEffect(() => {
  //   if (serverColorSchema) {
  //     setThemeColors(serverColorSchema.data);
  //   }
  // }, [serverColorSchema]);

  return (
    <ThemeConfigContext.Provider value={{ setColor: (k, v) => setThemeColors({ ...themeColors, [k]: v }) }}>
      <ThemeProvider theme={{ colors: { ...themeColors, ...otherThemeColors } }}>{props.children}</ThemeProvider>
    </ThemeConfigContext.Provider>
  )
}

////////////////////////////////////////////////////////////////////////////////////////////

// type ColorSchemeResponse = {
//   data: ColorSchemaType;
// };

// function useColorSchemes() {
//   const fetch = useAuthorizedFetch();

//   const callback = React.useCallback((): Promise<ColorSchemeResponse> => {
//     return fetch(makeUrl("/v1/web/branding/scheme")).then((r) => r.json());
//   }, [fetch]);

//   return useQuery(["color_scheme"], callback);
// }

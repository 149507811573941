import React from 'react'
import styled, { useTheme } from 'styled-components'
import { Span } from './Span'

interface Props {
  preText?: string
  text: string
  color: string
}

export default function Label({ text, color, preText }: Props) {
  const theme = useTheme()

  return (
    <Container bgColor={color}>
      {preText ? (
        <PreText size="12" color={getLightnessLevel(color) > 200 ? theme.colors.black : theme.colors.plainWhite}>
          {preText}
        </PreText>
      ) : null}
      <Text size="12" color={getLightnessLevel(color) > 200 ? theme.colors.black : theme.colors.plainWhite}>
        {text}
      </Text>
    </Container>
  )
}

const Container = styled.span<{ bgColor: string }>`
  display: inline-block;
  padding: 3px 8px;
  background-color: ${(p) => p.bgColor};
  border-radius: 20px;
  text-overflow: ellipsis;
  white-space: nowrap;
`

const Text = styled(Span)<{ color: string }>`
  color: ${(p) => p.color};
  font-weight: 600;
  line-height: 20px;
  font-size: 10px;
`

const PreText = styled(Span)<{ color: string }>`
  color: ${(p) => p.color};
  padding-right: 5px;
`

/// result: 0..255
function getLightnessLevel(color: string) {
  try {
    const c = color.substring(1) // strip #
    const rgb = parseInt(c, 16) // convert rrggbb to decimal
    const r = (rgb >> 16) & 0xff // extract red
    const g = (rgb >> 8) & 0xff // extract green
    const b = (rgb >> 0) & 0xff // extract blue

    const luma = 0.2126 * r + 0.7152 * g + 0.0722 * b // per ITU-R BT.709

    return luma
  } catch {
    return 0
  }
}

import React, { FC } from 'react'
import styled from 'styled-components'
import { Icon } from '@iconify/react'
import cn from 'classnames'

const StyledSwitch = styled.div`
  width: fit-content;
  &.controlled {
    cursor: pointer;
  }
`

interface SwitchProps {
  checked?: boolean
  onToggle?: (checked?: boolean) => void
}

const Switch: FC<SwitchProps> = ({ checked = false, onToggle }: SwitchProps) => (
  <StyledSwitch
    onClick={() => onToggle && onToggle(!Boolean(checked))}
    className={cn({ controlled: Boolean(onToggle) })}
  >
    {checked ? (
      <Icon icon="uil:toggle-on" width={30} color="#499976" />
    ) : (
      <Icon icon="uil:toggle-off" width={30} color="#d06d6e" />
    )}
  </StyledSwitch>
)

export { Switch }

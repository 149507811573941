import React, { FC, useCallback } from 'react'
import { Icon } from '@iconify/react'
import { deleteFeatureGroupFn, getFeatureGroupsFn } from 'api'
import { LoadingPage } from 'components/molecules/Loader'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { LinkTo } from 'components/atoms/Pointer'
import Button, { IconButton } from 'components/molecules/Button'
import { MODALS } from 'components/organisms/Modal'
import { FeatureGroup } from '@types'
import Table from 'components/organisms/Table'
import { useTranslation } from 'react-i18next'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet } from 'utils/array'
import Header from 'components/organisms/Table/atoms/Header'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { highlight } from 'utils/highlight'
import { useConfirm } from 'components/organisms/ConfirmDialog'
import { toast } from 'react-toastify'
import { Dropdown } from 'components/molecules/Dropdown'
import { MenuItem, Popup } from 'components/molecules/Popup'
import { Page, PageHeader } from 'components/organisms/layout'
import { useUserContext } from 'contexts/UserContext'

const columns: Column<FeatureGroup>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.featureGroup')} />
    },
    accessor: 'name',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => null,
    accessor: 'i_featuregroup',
    id: 'delete',
    Cell: ({ value: id, row }) => {
      const [t] = useTranslation()
      const { name } = row.original
      const queryClient = useQueryClient()
      const { confirm } = useConfirm()

      const { mutate: deleteFeatureGroup } = useMutation(deleteFeatureGroupFn, {
        onSuccess: (res) => {
          if (res) {
            queryClient.invalidateQueries(['feature-groups'])
            toast.success(t('page.featureGroups.successDeleted', { name }))
          }
        },
      })

      return (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <LinkTo to={`/feature-groups/${id}?modal=${MODALS.editFeatureGroup}`}>
              <MenuItem value="edit" icon={<Icon icon="uil:edit" />} onClick={async () => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
            <MenuItem
              value="delete"
              red
              icon={<Icon icon="uil:trash" />}
              onClick={async () => {
                const isConfirmed = await confirm(
                  <p style={{ width: '210px' }}>
                    {t('page.featureGroups.reallyDelete')} <b>{name}</b>?
                  </p>
                )
                if (isConfirmed) {
                  deleteFeatureGroup(id)
                }
              }}
            >
              {t('common.delete')}
            </MenuItem>
          </Popup>
        </Dropdown>
      )
    },
    width: 30,
    disableSortBy: true,
  },
]

const FeatureGroups: FC = () => {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const { data: featureGroups, isLoading } = useQuery(['feature-groups', i_realm], getFeatureGroupsFn)

  const tableProps = useTable(
    {
      columns,
      data: featureGroups ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  if (isLoading || !featureGroups) {
    return <LoadingPage />
  }

  return (
    <Page title={t('page.featureGroups.title')}>
      <PageHeader
        title={t('page.featureGroups.title')}
        subtitle={featureGroups?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        actions={
          <LinkTo to={`/feature-groups?modal=${MODALS.addFeatureGroup}`}>
            <Button icon={<Icon icon="uil:plus" />} appearance="blue" style={{ marginLeft: '12px' }}>
              {t('page.featureGroups.addGroup')}
            </Button>
          </LinkTo>
        }
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
}

const globalFilterFunc = (
  rows: Row<FeatureGroup>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<FeatureGroup>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { name } = r.original

      if (isIncludeFields([name], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default FeatureGroups

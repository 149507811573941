import React, { FC } from 'react'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { Checkbox } from 'components/molecules/Checkbox'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

const LogsFilter: FC<{ globalFilter: any; onChangeFilter: (option: string) => void }> = ({
  globalFilter,
  onChangeFilter,
}) => {
  const [t] = useTranslation()
  return (
    <Container>
      {[
        { label: t('page.activity.filter.login'), option: 'logged in' },
        { label: t('page.activity.filter.logout'), option: 'logged out' },
        { label: t('page.activity.filter.qr'), option: 'QR' },
        { label: t('page.activity.filter.updateUser'), option: 'updateUser' },
        { label: t('page.activity.filter.createInfoMessage'), option: 'createInfoMessage' },
        { label: t('page.activity.filter.updateInfoMessage'), option: 'updateInfoMessage' },
        { label: t('page.activity.filter.createFeatureGroup'), option: 'createFeatureGroup' },
        { label: t('page.activity.filter.updateFeatureGroup'), option: 'updateFeatureGroup' },
      ].map(({ option, label }) => (
        <FilterItem key={option}>
          <BlackParagraph text={label} />
          <Checkbox
            style={{ marginLeft: '4px' }}
            checked={globalFilter.actions.includes(option)}
            onToggle={() => onChangeFilter(option)}
          />
        </FilterItem>
      ))}
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`

const FilterItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export { LogsFilter }

import React, { useState } from 'react'
import styled from 'styled-components'
import Paragraph from './Paragraph'

interface TooltipProps {
  children: React.ReactNode
  message: string
  width?: number
  disabled?: boolean
}

const Tooltip: React.FC<TooltipProps> = ({ children, message, width, disabled }) => {
  const [isVisible, setVisibility] = useState(false)

  return (
    <Wrapper onMouseEnter={() => !disabled && setVisibility(true)} onMouseLeave={() => setVisibility(false)}>
      {children}
      {isVisible && <TooltipMessage text={message} style={{ width: `${width}px` }} />}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: inline-block;
  position: relative;
`

const TooltipMessage = styled(Paragraph)`
  min-width: 132px;
  background-color: #3b3b3b;
  color: white;
  position: absolute;
  bottom: 30px;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 999;
`

export default Tooltip

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getRealmsFn, updateRealmFn } from 'api'
import { Page, PageHeader } from 'components/organisms/layout'
import { LoadingPage } from 'components/molecules/Loader'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByNumber } from 'utils/array'
import { Realm } from '@types'
import { BlackParagraph } from 'components/atoms/Paragraph'
import { highlight } from 'utils/highlight'
import Table from 'components/organisms/Table'
import { Switch } from 'components/molecules/Switch'
import Header from 'components/organisms/Table/atoms/Header'
import { useConfirm } from 'components/organisms/ConfirmDialog'
import Button from 'components/molecules/Button'
import { LinkTo } from 'components/atoms/Pointer'
import { MODALS } from 'components/organisms/Modal'
import { Icon } from '@iconify/react'

interface GlobalFilter {
  search: string
}

const columns: Column<Realm>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.name')} />
    },
    accessor: 'name',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('realm.domain')} />
    },
    accessor: 'domain',
    sortType: (a, b) => sortByAlphabet(a.values.domain, b.values.domain),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('realm.css_base_url')} />
    },
    accessor: 'css_base_url',
    sortType: (a, b) => sortByAlphabet(a.values.css_base_url, b.values.css_base_url),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('realm.xmpp_fallback')} />
    },
    accessor: 'xmpp_fallback',
    sortType: (a, b) => sortByAlphabet(a.values.xmpp_fallback, b.values.xmpp_fallback),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('realm.default_realm')} />
    },
    accessor: 'default_realm',
    sortType: (a, b) => sortByNumber(a.values.default_realm, b.values.default_realm),
    Cell: ({ value, row }) => {
      const [t] = useTranslation()
      const { confirm } = useConfirm()
      const queryClient = useQueryClient()
      const newRealm = { ...row.original, default_realm: Number(!Boolean(value)) }

      const { mutate: updateRealm } = useMutation(updateRealmFn, {
        onSuccess: (res) => {
          if (res) {
            queryClient.invalidateQueries(['realms'])
          }
        },
      })

      return (
        <Switch
          checked={Boolean(value)}
          onToggle={async () => {
            const isConfirmed = await confirm(
              <p style={{ width: '210px' }}>
                {value ? t('page.apps.reallyDisable') : t('page.apps.reallyEnable')}
                <b>{` ${newRealm.name} (${newRealm.domain}) `}</b>?
              </p>,
              <Button appearance={value ? 'red' : 'outline'}>{value ? t('common.disable') : t('common.enable')}</Button>
            )
            if (isConfirmed) {
              updateRealm(newRealm)
            }
          }}
        />
      )
    },
  },
  {
    Header: () => null,
    accessor: 'i_realm',
    disableSortBy: true,
    Cell: () => {
      return <div />
    },
    width: 0,
  },
]

// common.default

const Realms: React.FC = () => {
  const [t] = useTranslation()
  const { data: realms, isLoading } = useQuery(['realms'], getRealmsFn)

  const tableProps = useTable(
    {
      columns,
      data: realms || emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  if (isLoading || !realms) {
    return <LoadingPage />
  }

  return (
    <Page title={t('navBar.realms')}>
      <PageHeader
        title={t('navBar.realms')}
        search={globalFilter.search}
        subtitle={realms?.length.toString()}
        actions={
          <LinkTo to={`/realms?modal=${MODALS.addRealm}`}>
            <Button icon={<Icon icon="gridicons:domains" />} appearance="blue" style={{ marginLeft: '12px' }}>
              {t('page.realms.addRealm')}
            </Button>
          </LinkTo>
        }
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

const globalFilterFunc = (rows: Row<Realm>[], _columnIds: string[], filterValue: GlobalFilter): Row<Realm>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { name } = r.original

      if (isIncludeFields([name], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Realms

import { Icon } from '@iconify/react'
import React, { FC } from 'react'
import { IconButton, IconButtonProps } from './IconButton'

const CloseButton: FC<IconButtonProps> = (props) => {
  return (
    <IconButton {...props}>
      <Icon icon="uil:multiply" />
    </IconButton>
  )
}

export { CloseButton }

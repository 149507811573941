import { DASH_PLACEHOLDER } from './consts'

export const parseInfoField = (info?: string) => {
  if (!info) return { role: DASH_PLACEHOLDER, cabinet: DASH_PLACEHOLDER }

  const infoArray = info.split(',')
  return {
    role: infoArray?.[0]?.trim() || DASH_PLACEHOLDER,
    cabinet: infoArray?.[1]?.trim() || DASH_PLACEHOLDER,
  }
}

import { Headline12NotoSansBold } from 'components/atoms/Headline'
import Icon from 'components/atoms/Icon'
import React from 'react'
import styled from 'styled-components'

interface Props {
  name: string
  iconName?: string
  selected?: boolean
}

export default function NavItem({ name, iconName, selected }: Props) {
  return (
    <Container>
      <div>{iconName && <Icon icon={iconName} width={24} color={selected ? '#1C658E' : '#505050'} />}</div>
      <Headline12NotoSansBold color={selected ? '#1C658E' : '#505050'}>{name}</Headline12NotoSansBold>
    </Container>
  )
}

const Container = styled.div`
  display: grid;
  align-items: center;
  padding-left: 17px;
  grid-template-columns: 30px 1fr;
  grid-column-gap: 10px;
  cursor: pointer;
  min-height: 20px;
`

import React from 'react'
import styled from 'styled-components'
import BackgroundPicture from 'components/assets/login-picture.png'
import { ReactComponent as LogoSvg } from 'components/assets/logo.svg'
import LoginForm from './LoginForm'

export default function Login() {
  return (
    <Container>
      <Content>
        <Header>
          <Logo />
        </Header>
        <LoginForm />
      </Content>
      <Image />
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  min-height: 100vh;
`
const Header = styled.div`
  height: 285px;
  background: #f0f8ff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const Logo = styled(LogoSvg)`
  width: 240px;
`

const Content = styled.div`
  width: 400px;
`

const Image = styled.div`
  background-image: url(${BackgroundPicture});
  background-size: cover;
  flex: 1;
`

import React, { FC } from 'react'
import styled from 'styled-components'
import Button from 'components/molecules/Button'
import { Link } from 'react-router-dom'

const StyledNotFound = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  width: 100%;
  padding: 32px;

  .not-found__container {
    display: flex;
  }

  .not-found__description {
    display: flex;
    flex-direction: column;
  }

  .not-found__main {
    max-width: 400px;
    font-size: 28px;
  }

  .not-found__sub {
    max-width: 340px;
    margin-top: 16px;
    color: #767676;
    font-size: 20px;
  }

  .not-found__button {
    width: fit-content;
    margin-top: 16px;
    text-decoration: none;
  }
`

const NotFound: FC = () => (
  <StyledNotFound>
    <div className="not-found__container">
      <div className="not-found__description">
        <h1 className="not-found__main">Oops! The page you were looking for doesn’t exist.</h1>
        <h2 className="not-found__sub">You have misstyped the address or the page may have moved.</h2>
        <Link className="not-found__button" to="/">
          <Button appearance="blue">Go to homepage</Button>
        </Link>
      </div>
    </div>
  </StyledNotFound>
)

export default NotFound

import { FC, cloneElement, isValidElement, useRef } from 'react'
import { createPortal } from 'react-dom'
import styled from 'styled-components'

import { useOnClickOutside } from 'hooks/useOnClickOutside'
import Button from 'components/molecules/Button'
import { useConfirm } from './useConfirm'
import { useTranslation } from 'react-i18next'

const StyledConfirmDialog = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  overflow: auto;
  background-color: #3e3e3e85;

  .dialog-content {
    position: relative;

    top: 40%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 32px;
    width: 268px;
    max-width: fit-content;
    background-color: #fff;
    border-radius: 16px;
    animation-name: animateOpen;
    animation-duration: 0.4s;
  }

  .dialog__description {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-top: 6px;
  }

  .dialog__actions {
    align-self: stretch;
    margin-top: 32px;
  }

  .actions {
    display: flex;
    justify-content: space-between;

    &__delete {
      padding: 6px 26px;
    }

    &__cancel {
      padding: 6px 26px;
    }
  }

  @keyframes animateOpen {
    from {
      transform: scale(0.96);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`
const ConfirmDialog: FC = () => {
  const [t] = useTranslation()
  const { onConfirm, onCancel, confirmState } = useConfirm()

  const confirmAction =
    confirmState?.customAction &&
    isValidElement(confirmState.customAction) &&
    cloneElement(confirmState.customAction, { onClick: onConfirm })

  const ref = useRef<HTMLDivElement | null>(null)
  useOnClickOutside(ref, onCancel)

  const portalElement = document.getElementById('modal-root')

  const component = confirmState.show ? (
    <StyledConfirmDialog>
      <div className="dialog-content" ref={ref}>
        <div className="dialog__description">{confirmState?.text && confirmState.text}</div>
        <div className="dialog__actions actions">
          {confirmAction || (
            <Button className="actions__delete" appearance="red" onClick={onConfirm}>
              {t('common.delete')}
            </Button>
          )}
          <Button className="actions__cancel" appearance="flat" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        </div>
      </div>
    </StyledConfirmDialog>
  ) : null

  return createPortal(component, portalElement!)
}

export { ConfirmDialog }

import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { BlackBoldParagraph, GreyParagraph2 } from 'components/atoms/Paragraph'
import ReactSelect from 'components/molecules/ReactSelect'
import { AppArch, AppPlatform, AppVersionRequest } from '@types'
import { toast } from 'react-toastify'
import { createAppVersionFn } from 'api'
import { useTranslation } from 'react-i18next'
import { Switch } from 'components/molecules/Switch'

const osOptions: { label: string; value: AppPlatform }[] = [
  { label: 'iOS', value: 'IOS' },
  { label: 'Android', value: 'ANDROID' },
  { label: 'Windows', value: 'DESKTOP_WIN' },
  { label: 'MacOS', value: 'DESKTOP_MAC' },
  { label: 'Linux', value: 'DESKTOP_LINUX' },
]
const archOptions: { label: string; value: AppArch }[] = [
  { label: 'x64', value: 'x64' },
  { label: 'x86', value: 'x86' },
]

const AddApp: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const {
    control,
    formState: { isDirty },
    handleSubmit,
  } = useForm<AppVersionRequest>()

  const { mutate: addAppVersion } = useMutation((data: AppVersionRequest) => createAppVersionFn(data), {
    onSuccess: ({ version_name, version_code }) => {
      queryClient.invalidateQueries('app-versions')
      toast.success(`App version ${version_name} (${version_code}) successfully added!`)
      onClose()
    },
  })

  const onSubmit = (data: AppVersionRequest) => {
    addAppVersion(data)
  }

  return (
    <ModalView title={t('modal.addApp.title')} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <div>
              <GreyParagraph2
                style={{ marginLeft: '15px', marginBottom: '5px' }}
                size="11"
                text={t('common.platform')}
              />
              <Controller
                name="platform"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <ReactSelect
                      value={osOptions.filter((g) => g.value === value)}
                      onChange={({ value }) => {
                        onChange(value)
                      }}
                      options={osOptions}
                      placeholder={t('modal.addApp.chooseOs')}
                      {...props}
                    />
                  )
                }}
              />
            </div>
            <div>
              <GreyParagraph2 style={{ marginLeft: '15px', marginBottom: '5px' }} size="11" text={t('common.arch')} />
              <Controller
                name="arch"
                control={control}
                render={({ field: { ref, onChange, value, name, ...props } }) => {
                  return (
                    <ReactSelect
                      value={archOptions.filter((g) => g.value === value)}
                      onChange={({ value }) => {
                        onChange(value)
                      }}
                      options={archOptions}
                      placeholder={t('modal.addApp.chooseArch')}
                      {...props}
                    />
                  )
                }}
              />
            </div>
          </Field>
          <Field>
            <Controller
              name="version_name"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <InputWithCaptions placeholder="1.0.0" type="text" caption={t('common.version')} {...props} />
              )}
            />
            <Controller
              name="version_code"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <InputWithCaptions placeholder="100" type="text" caption={t('common.versionCode')} {...props} />
              )}
            />
          </Field>
          <Field>
            <Controller
              name="download_url"
              defaultValue=""
              control={control}
              render={({ field: { ref, name, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.downloadUrl')} {...props} />
              )}
            />
          </Field>

          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Controller
              name="enabled"
              control={control}
              defaultValue={false}
              render={({ field: { value, onChange } }) => (
                <div
                  style={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-around' }}
                >
                  <BlackBoldParagraph text={t('common.enabled')} />
                  <Switch checked={value} onToggle={onChange} />
                </div>
              )}
            />
            <Button style={{ alignSelf: 'flex-end' }} appearance="blue" type="submit" disabled={!isDirty}>
              {t('modal.addApp.submit')}
            </Button>
          </div>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`
const Field = styled.div`
  display: flex;
  flex-flow: wrap;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    flex: 1;
  }
`

export default AddApp

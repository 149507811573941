import React, { FC } from 'react'
import styled from 'styled-components'
import { ModalProps, ModalView } from 'components/organisms/Modal'
import Button from 'components/molecules/Button'
import { useMutation, useQueryClient } from 'react-query'
import { Controller, useForm } from 'react-hook-form'
import InputWithCaptions from 'components/molecules/InputWithCaptions'
import { createFeatureGroupsFn } from 'api'
import { FeatureGroup } from '@types'
import { useTranslation } from 'react-i18next'
import { SwitchField } from '../components/SwitchField'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'

const defaultConfig = {
  privacy: 1,
  multiple_identities: 0,
  multiple_devices: 0,
  pstn: 0,
  promo_credits: 5,
  ptt: 0,
  notarized_receipts: 0,
  hide_app: 0,
  ptt_default_speaker: 0,
  ptt_force_enable_from_contacts: 0,
  video_conf: 0,
  show_location_during_call: 0,
  auto_message_disappear: 0,
  call_recording: 0,
  cr_auto_activate: 0,
  cr_show_controls: 0,
  cr_save_mode: 0,
  local_contacts_only: 0,
  message_security_options: 1,
  max_daily_transfer: 3,
  min_leftover_balance: 5,
  max_transfer_amount: 50,
  registration_mode: 2,
  disable_p2p: 0,
  external_auth: 0,
  unrestricted_voip: 0,
  show_channels: 1,
  local_contacts_editing: 1,
  roster_contacts: 0,
  server_contacts: 0,
  external_contact_triggers: 1,
}

const AddFeatureGroup: FC<ModalProps> = ({ onClose }) => {
  const [t] = useTranslation()
  const queryClient = useQueryClient()
  const {
    control,
    formState: { isDirty, isValid },
    handleSubmit,
  } = useForm<FeatureGroup>({ defaultValues: defaultConfig })

  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const isSuperAdmin = state.admin?.type === Roles.SuperAdmin

  const { mutate: createFeatureGroup } = useMutation(
    (data: Partial<FeatureGroup>) => createFeatureGroupsFn({ ...data, i_realm }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('feature-groups')
        onClose()
      },
    }
  )

  const onSubmit = (values: FeatureGroup) => {
    createFeatureGroup(values)
  }

  return (
    <ModalView title={t('modal.addFeatureGroup.title')} style={{ maxWidth: '750px' }} onClose={onClose}>
      <Content>
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Field>
            <Controller
              name="name"
              defaultValue=""
              control={control}
              rules={{ required: true }}
              render={({ field: { ref, name, ...props } }) => (
                <InputWithCaptions type="text" caption={t('common.name')} {...props} />
              )}
            />
          </Field>
          <Grid>
            <SwitchField name="external_contact_triggers" title="External contact triggers" control={control} />
            <SwitchField name="local_contacts_editing" title="Contacts editing" control={control} />
            <SwitchField name="local_contacts_only" title="Allow local contacts" control={control} />
            <SwitchField name="roster_contacts" title="Load contacts from server" control={control} />
            <SwitchField name="show_channels" title="Show channels" control={control} />
            <SwitchField name="multiple_devices" title="Multiple devices" control={control} />
            {isSuperAdmin && (
              <>
                <SwitchField name="external_auth" title="External Auth" control={control} />
                <SwitchField name="auto_message_disappear" title="Auto message dissapear" control={control} />
                <SwitchField name="call_recording" title="Call recording" control={control} />

                <SwitchField name="disable_p2p" title="Disable p2p" control={control} />
                <SwitchField name="hide_app" title="Hide app" control={control} />
                <SwitchField name="message_security_options" title="Message security options" control={control} />
                <SwitchField name="multiple_identities" title="Multiple identities" control={control} />
                <SwitchField name="notarized_receipts" title="Notarized receipts" control={control} />
                <SwitchField name="privacy" title="Privacy" control={control} />
                <SwitchField name="promo_credits" title="Promo credits" control={control} />
                <SwitchField name="ptt" title="Allow push-to-talk" control={control} />
                <SwitchField name="server_contacts" title="Allow server contacts" control={control} />
                <SwitchField name="show_location_during_call" title="Show location during call" control={control} />
                <SwitchField name="unrestricted_voip" title="Unrestricted VoIP" control={control} />
                <SwitchField name="video_conf" title="Video conference" control={control} />

                <SwitchField name="cr_auto_activate" title="CR auto activate" control={control} />
                <SwitchField name="cr_save_mode" title="CR save mode" control={control} />
                <SwitchField name="cr_show_controls" title="CR show controls" control={control} />
                <SwitchField name="ptt_default_speaker" title="PTT default speaker" control={control} />
                <SwitchField
                  name="ptt_force_enable_from_contacts"
                  title="PTT force enable from contacts"
                  control={control}
                />
                <div />

                <Controller
                  name="force_jitsi_call_participants_amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Force Jitsi call participants amount" {...props} />
                  )}
                />

                <Controller
                  name="max_daily_transfer"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Max daily transfer" {...props} />
                  )}
                />
                <Controller
                  name="max_transfer_amount"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Max transfer amount" {...props} />
                  )}
                />
                <Controller
                  name="min_leftover_balance"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, name, ...props } }) => (
                    <InputWithCaptions type="number" caption="Min leftover balance" {...props} />
                  )}
                />
              </>
            )}
          </Grid>

          <SubmitButton appearance="blue" type="submit" disabled={!isDirty || !isValid}>
            {t('modal.addFeatureGroup.addGroup')}
          </SubmitButton>
        </Form>
      </Content>
    </ModalView>
  )
}

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
`

const Grid = styled.div`
  display: grid;
  max-height: calc(100vh - 280px);
  grid-template-columns: repeat(auto-fill, 220px);
  grid-gap: 10px;
  width: 100%;
  overflow: auto;
`

const Field = styled.div`
  display: flex;
  margin-bottom: 24px;
  gap: 12px;
  & > * {
    width: 230px;
  }
`

const SubmitButton = styled(Button)`
  margin-top: 32px;
  align-self: flex-end;
`

export default AddFeatureGroup

import React, { FC, useCallback } from 'react'
import { BlackParagraph } from 'components/atoms/Paragraph'
import Table from 'components/organisms/Table'
import Header from 'components/organisms/Table/atoms/Header'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet, sortByDate } from 'utils/array'
import { highlight } from 'utils/highlight'
import { DATE_TIME_FORMAT } from 'utils/date'
import { getDevicesFn } from 'api'
import { Page, PageHeader } from 'components/organisms/layout'
import { useUserContext } from 'contexts/UserContext'

interface FormattedDevice {
  i_device: number
  user: string
  deviceos: string
  clientversion: string
  created: string
  last_login: string
}

const columns: Column<FormattedDevice>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.user')} />
    },
    accessor: 'user',
    sortType: (a, b) => sortByAlphabet(a.values.user, b.values.user),
    Cell: ({ value, state: { globalFilter } }) => (
      <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    ),
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.os')} />
    },
    accessor: 'deviceos',
    Cell: ({ value, state: { globalFilter } }) => {
      if (!value) return <div>-</div>
      return <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    },
    width: 80,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.version')} />
    },
    accessor: 'clientversion',
    Cell: ({ value, state: { globalFilter } }) => {
      if (!value) return <div>-</div>
      return <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.created')} />
    },
    accessor: 'created',
    sortType: (a, b) => sortByDate(a.values.created, b.values.created),
    Cell: ({ value }) => {
      const date = value ? dayjs(value as string).format(DATE_TIME_FORMAT) : '-'
      return <BlackParagraph text={date} size="12" />
    },
    width: 100,
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.lastLogin')} />
    },
    accessor: 'last_login',
    sortType: (a, b) => sortByDate(a.values?.last_login, b.values?.last_login),
    Cell: ({ value }) => {
      if (!value) return <div>-</div>
      const date = dayjs(value).format(DATE_TIME_FORMAT)
      return <BlackParagraph text={date} size="12" />
    },
    width: 100,
  },
  {
    Header: () => null,
    accessor: 'i_device',
    disableSortBy: true,
    Cell: () => {
      return <div />
    },
    width: 0,
  },
]

const initialGlobalFilter = { feature_profile: [], search: '' }

const Devices: FC = () => {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm

  const { data: devices, isLoading } = useQuery(['devices', i_realm], getDevicesFn, {
    select: (data) => {
      const formattedData = data.map(({ i_device, clientversion, deviceos, customer: { user, created }, session }) => ({
        i_device,
        user: user?.user_id || '-',
        deviceos,
        clientversion,
        created,
        last_login: session?.last_login,
      })) as FormattedDevice[]

      return formattedData
    },
  })

  const tableProps = useTable(
    {
      columns,
      data: devices ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: { ...initialGlobalFilter } },
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  return (
    <Page title={t('page.devices.title')}>
      <PageHeader
        title={t('page.devices.title')}
        subtitle={tableProps?.rows?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
  isTest?: boolean
}

const globalFilterFunc = (
  rows: Row<FormattedDevice>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<FormattedDevice>[] => {
  let remainingRows = [...rows]

  remainingRows = remainingRows.filter((r) => {
    const { user, last_login } = r.original
    const isTestUser = user.toLocaleLowerCase().includes('test')
    const isActive = Boolean(last_login)
    return !isTestUser && isActive
  })

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { user, clientversion, deviceos } = r.original
      if (isIncludeFields([user, clientversion, deviceos], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Devices

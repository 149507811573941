import React, { FC, useCallback } from 'react'
import { getAdminsFn } from 'api'
import { AdminUser } from '@types'
import { BlackParagraph, GreyParagraph } from 'components/atoms/Paragraph'
import { LoadingPage } from 'components/molecules/Loader'
import Table from 'components/organisms/Table'
import Header from 'components/organisms/Table/atoms/Header'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import {
  Column,
  Row,
  useExpanded,
  useFlexLayout,
  useGlobalFilter,
  usePagination,
  useResizeColumns,
  useSortBy,
  useTable,
} from 'react-table'
import { emptyArray, isIncludeFields, sortByAlphabet } from 'utils/array'
import { highlight } from 'utils/highlight'
import Label from 'components/atoms/Label'
import { LinkTo } from 'components/atoms/Pointer'
import Button, { IconButton } from 'components/molecules/Button'
import Icon from 'components/atoms/Icon'
import { MODALS } from 'components/organisms/Modal'
import { Page, PageHeader } from 'components/organisms/layout'
import { useUserContext } from 'contexts/UserContext'
import { Roles } from 'utils/consts'
import { Dropdown } from 'components/molecules/Dropdown'
import { MenuItem, Popup } from 'components/molecules/Popup'

const columns: Column<AdminUser>[] = [
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.username')} />
    },
    accessor: 'username',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => {
      const { state } = useUserContext()
      const username = state.admin?.username
      const itsYou = username === value

      return (
        <div style={{ display: 'flex' }}>
          <BlackParagraph text={highlight(value, globalFilter.search)} size="12" />
          {itsYou && <GreyParagraph text="(it's you)" style={{ marginLeft: '4px' }} />}
        </div>
      )
    },
  },
  {
    Header: () => {
      const [t] = useTranslation()
      return <Header text={t('common.role')} />
    },
    accessor: 'type',
    sortType: (a, b) => sortByAlphabet(a.values.name, b.values.name),
    Cell: ({ value, state: { globalFilter } }) => (
      <Label
        text={highlight(value, globalFilter.search) as string}
        color={value === Roles.SuperAdmin ? '#d9ae44c2' : '#3a8f5ad4'}
      />
    ),
  },
  {
    Header: () => null,
    accessor: 'id',
    id: 'edit',
    Cell: (cell) => {
      const [t] = useTranslation()
      const { id } = cell.row.original
      const { isSuperAdmin } = useUserContext()

      return isSuperAdmin ? (
        <Dropdown
          button={
            <IconButton>
              <Icon icon="uil:ellipsis-v" />
            </IconButton>
          }
        >
          <Popup>
            <LinkTo to={`/admin/${id}?modal=${MODALS.editAdmin}`}>
              <MenuItem value="edit-admin" icon={<Icon icon="uil:edit" />} onClick={() => {}}>
                {t('common.edit')}
              </MenuItem>
            </LinkTo>
          </Popup>
        </Dropdown>
      ) : null
    },
    width: 30,
    disableSortBy: true,
  },
]

const Log: FC = () => {
  const [t] = useTranslation()
  const { state } = useUserContext()
  const { i_realm } = state?.realm
  const role = state.admin?.type

  const { data: admins, isLoading } = useQuery(['admins', i_realm], getAdminsFn, {
    select: (data) => data.filter(({ type }) => (role === Roles.SuperAdmin ? true : type !== Roles.SuperAdmin)),
  })

  const tableProps = useTable(
    {
      columns,
      data: admins ?? emptyArray,
      initialState: { pageSize: 20, globalFilter: { search: '' } }, // Pass our hoisted table state
      globalFilter: globalFilterFunc,
      autoResetPage: false,
      disableSortRemove: true,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useFlexLayout,
    useResizeColumns
  )

  const globalFilter: GlobalFilter = tableProps.state.globalFilter

  const handleChangeSearch = useCallback(
    (search: string) => {
      tableProps.setGlobalFilter({ ...globalFilter, search })
    },
    [globalFilter, tableProps]
  )

  if (isLoading || !admins) {
    return <LoadingPage />
  }

  return (
    <Page title={t('page.admins.title')}>
      <PageHeader
        title={t('page.admins.title')}
        subtitle={admins?.length.toString()}
        search={globalFilter.search}
        onChangeSearch={handleChangeSearch}
        actions={
          role === Roles.SuperAdmin && (
            <LinkTo to={`/admin?modal=${MODALS.addAdmin}`}>
              <Button icon={<Icon icon="uil:plus" />} appearance="blue" style={{ marginLeft: '12px' }}>
                {t('page.admins.addAdmin')}
              </Button>
            </LinkTo>
          )
        }
      />
      <Table isLoading={isLoading} {...tableProps} />
    </Page>
  )
}

interface GlobalFilter {
  search: string
}

const globalFilterFunc = (
  rows: Row<AdminUser>[],
  _columnIds: string[],
  filterValue: GlobalFilter
): Row<AdminUser>[] => {
  let remainingRows = [...rows]

  if (filterValue.search) {
    const escapedValue = filterValue.search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
    const regExp = new RegExp(`${escapedValue}`, 'i')

    remainingRows = remainingRows.filter((r) => {
      const { username, type } = r.original

      if (isIncludeFields([username, type], regExp)) {
        return true
      }
      return false
    })
  }

  return remainingRows
}

export default Log

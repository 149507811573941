import React, { ReactNode, FC, MutableRefObject, useState, useEffect } from 'react'
import cn from 'classnames'
import styled from 'styled-components'

import { useLockBodyScroll } from 'hooks/useLockBodyScroll'

const StyledModal = styled.div`
  position: fixed;
  z-index: 1;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal__overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
    background-color: #3e3e3e85;
    opacity: 1;
    transition: opacity 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }

  .modal__card {
    display: flex;
    outline: 0;
    position: fixed;
    flex-direction: column;
    padding: 16px 24px;
    opacity: 0;
    transition: all 220ms ease-out;
    transform: scale(0.96);
    background: #fff;
    border-radius: 12px;

    max-height: 100%;
    max-width: 100%;
    /* overflow: auto; */

    &--opened {
      transform: scale(1);
      opacity: 1;
      transition: all 220ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    }
  }
`

interface ModalLayoutProps {
  children: ReactNode
  modalCardRef: MutableRefObject<null>
}

const ModalLayout: FC<ModalLayoutProps> = ({ children, modalCardRef }) => {
  useLockBodyScroll()
  const [cardOpen, setCardOpen] = useState<boolean>(false)

  useEffect(() => {
    setCardOpen(true)

    return () => setCardOpen(false)
  }, [])

  return (
    <StyledModal>
      <div className="modal__overlay" />
      <div
        className={cn('modal__card', {
          'modal__card--opened': cardOpen,
        })}
        ref={modalCardRef}
      >
        {children}
      </div>
    </StyledModal>
  )
}

export { ModalLayout }

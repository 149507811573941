import React, { FC, ButtonHTMLAttributes, SVGAttributes, ReactElement } from 'react'
import styled from 'styled-components'
import cn from 'classnames'

const StyledButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  background: none;
  border-radius: 50%;
  transition: all 0.1s;
  svg {
    color: #727272;
  }

  &.size--default {
    width: 30px;
    height: 30px;
  }

  &.size--large {
    width: 40px;
    height: 40px;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    cursor: pointer;
    backdrop-filter: brightness(0.95);
    svg {
      color: #232323;
    }
  }

  &:focus,
  &:active {
    backdrop-filter: brightness(0.92);
    outline: none;
    svg {
      color: #232323;
    }
  }
`

export interface IconButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  size?: 'default' | 'large'
  children?: ReactElement<SVGAttributes<SVGSVGElement>>
}

const IconButton: FC<IconButtonProps> = ({ children, className, size = 'default', ...rest }: IconButtonProps) => (
  <StyledButton type="button" className={cn(className, `size--${size}`)} {...rest}>
    {children}
  </StyledButton>
)

export { IconButton }

import { InfoMessage } from '@types'
import InfoMessages from './InfoMessages'

export const infoMessageTypesOptions: { label: string; value: InfoMessage['type'] }[] = [
  { label: 'alert', value: 'alert' },
  { label: 'webview', value: 'webview' },
]
export type ButtonType = 'openurl' | 'cancel'

export default InfoMessages
